import { TrackingEventState } from '@swyft/swyft-common';
import { Modal, Button, Table } from 'antd';
import { LabelStateTag } from '../helpers/LabelHelpers';
import { trackingEventStateDescription } from '../consts';

interface Props {
  hideModal: () => void;
  isVisible: boolean;
}

export const LabelStateModal = ({ isVisible, hideModal }: Props) => {
  return (
    <Modal
      title="Label State Information"
      onCancel={hideModal}
      visible={isVisible}
      width={800}
      footer={[
        <Button key="done" onClick={hideModal}>
          Close
        </Button>,
      ]}
    >
      <TrackingStateDescriptionTable />
    </Modal>
  );
};

const TrackingStateDescriptionTable = () => {
  const data = Object.keys(trackingEventStateDescription).map((state: string) => {
    return {
      state,
      description: trackingEventStateDescription[state as TrackingEventState],
    };
  });
  const columns = [
    {
      title: 'State',
      dataIndex: 'state',
      render: (state: TrackingEventState) => <LabelStateTag state={state} />,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];
  return (
    <Table columns={columns} dataSource={data} size="small" pagination={false} rowKey="state" />
  );
};
