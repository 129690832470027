import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AuthContext } from '../components/AuthProvider';
import { Row, Col, Card, Space, Typography } from 'antd';
import React, { useContext } from 'react';
import { COMPANY_NAME } from '../consts';

const { Text } = Typography;

export const UserMisconfiguredCard: React.FunctionComponent = () => {
  //@ts-ignore
  const { user } = useContext(AuthContext);

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Row justify="center">
        <Col span={14}>
          <Card
            title={
              <>
                <ExclamationCircleOutlined />
                <Text> User Misconfigured </Text>
              </>
            }
            style={{ backgroundColor: '#fafafa' }}
          >
            <Row justify="center" align="middle">
              <Col>
                <Text>
                  The current user {user.email} is not assigned to a merchant or merchant group.
                </Text>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Text strong>{`Please contact ${COMPANY_NAME}!`}</Text>
            </Row>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};
