import * as _ from 'lodash';
import { CSV_TEMPLATE_COLUMNS } from '../../consts';

/**
 * Validate the header row for a csv upload.
 * Only check for existence of columns which are marked as required.
 * The lodash difference function only checks for values in the first array which
 * are not contained in the second.
 * Therefore this function will only return a non-empty array when
 * `headers` is missing a required field.
 * @param header
 * @returns array of column headers which are required and missing from the argument
 */
export const validateTemplateHeader = (header: string[]) => {
  const missingFields = CSV_TEMPLATE_COLUMNS.filter((col) => col.required).map(
    (col) => col.dataIndex
  );
  const missing = _.difference(missingFields, header);

  return missing;
};
