import React from 'react';
import { Button, Layout, message, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import firebase from '../services/firebase';
import { LogoutOutlined } from '@ant-design/icons';
import { isRebrandEnabled } from '@swyft/swyft-common/lib/consts';
import './css/Header.css';

interface Props {
  isSidebarCollapsed: Boolean;
}

export const Header: React.FunctionComponent<Props> = ({ isSidebarCollapsed }) => {
  const history = useHistory();

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        message.info('Successfully logged out');
        history.push('/login');
      })
      .catch((error) => {
        message.info('Failed to log out');
      });
  };

  return (
    <Layout.Header className={`header ${isSidebarCollapsed ? 'header-full' : 'header-collapsed'}`}>
      {isRebrandEnabled ? (
        <img src="/hero-helo.png" style={{ paddingLeft: 23, height: '60%' }} />
      ) : (
        <img src="/hero.png" style={{ paddingLeft: 23, height: '80%' }} />
      )}

      <Tooltip placement="bottom" title="Logout" arrowPointAtCenter>
        <Button type="default" onClick={handleLogout} icon={<LogoutOutlined />} />
      </Tooltip>
    </Layout.Header>
  );
};
