import firebase from './firebase';

export const FUNCTIONS = {
  updateCutoffTime: firebase
    .functions()
    .httpsCallable('httpsOnCallPickupLocations-updateCutoffTime'),
  updateSmsConfiguration: firebase
    .functions()
    .httpsCallable('httpsOnCallMerchants-updateSmsConfiguration'),
  testSmsNotification: firebase
    .functions()
    .httpsCallable('httpsOnCallMerchants-testSmsNotification'),
};
