import React, { useContext } from 'react';
import { MultiLabelsContainer } from '../../components/MultiLabelsContainer';
import { configuration, NULL_SEARCH_TOKEN } from '../../helpers/elastic-app-search-configuration';
import { SearchProvider } from '@elastic/react-search-ui';
import { AuthContext } from '../../components/AuthProvider';

export const MultiMerchantLabels: React.FunctionComponent = () => {
  //@ts-ignore
  const { merchantGroup } = useContext(AuthContext);
  const searchToken = merchantGroup?.searchToken || NULL_SEARCH_TOKEN;

  return (
    <SearchProvider config={configuration(searchToken)}>
      <MultiLabelsContainer validSearchToken={searchToken} />
    </SearchProvider>
  );
};
