import { IMerchant } from '@swyft/swyft-common';
import { Card, Col, Descriptions, Row, Space, Tag } from 'antd';
import * as _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../components/AuthProvider';
import * as FirestoreService from '../../services/firestore';
interface IMerchantGroupOverview extends IMerchant {
  cutoffTime: string;
}

export const Overview: React.FunctionComponent = () => {
  // @ts-ignore
  const { merchantGroup } = useContext(AuthContext);

  const [merchants, setMerchants] = useState([] as any);

  useEffect(() => {
    fetchMerchants(merchantGroup.merchantIds);
  }, [merchantGroup]);

  const fetchMerchants = async (merchantIds: string[]) => {
    const reqs = merchantIds.map(async (merchantId) => {
      const merchant = (await FirestoreService.getMerchant(merchantId)).data();
      const pickupLocation = await FirestoreService.getPickupLocationsForMerchantId(merchantId);
      return {
        ...merchant,
        cutoffTime: pickupLocation[0].cutoffTime,
      };
    });
    const merchantData = await Promise.all(reqs);
    setMerchants(merchantData);
  };

  const renderMerchantInfo = (merchants: IMerchantGroupOverview[]) => {
    return (
      <Space direction="vertical" style={{ display: 'flex' }}>
        {merchants.map((merchant) => {
          return (
            <Row key={merchant.id}>
              <Card
                title={
                  <div>
                    <Tag color="green">OPEN</Tag> {merchant.name}
                  </div>
                }
                extra={<a href="#">See labels</a>}
                style={{ width: '100%' }}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Descriptions title="Contact" bordered size="small" column={1}>
                      <Descriptions.Item label="Name">{merchant.name}</Descriptions.Item>
                      <Descriptions.Item label="Contact">
                        {merchant.contact?.firstName} {merchant.contact?.lastName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Phone">{merchant.contact?.phone}</Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {merchant.contact?.address.line1} {merchant.contact?.address.line2},
                        {merchant.contact?.address.city}, {merchant.contact?.address.country}
                      </Descriptions.Item>
                      <Descriptions.Item label="Cutoff Time">
                        {merchant.cutoffTime}
                      </Descriptions.Item>
                      <Descriptions.Item label="Pickup Time">
                        {merchant.pickUpTime}
                      </Descriptions.Item>
                      <Descriptions.Item label="Pickup Notes">
                        {merchant.pickUpNotes}
                      </Descriptions.Item>
                      <Descriptions.Item label="Delivery Window">
                        04:00 PM - 10:00 PM
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={12}>
                    <iframe
                      width="100%"
                      height="100%"
                      loading="lazy"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAcPpMaNbeqbClZbBxxEiTaZD_OM2goEzk
    &q=${merchant.contact?.address.line1}`}
                    ></iframe>
                  </Col>
                </Row>
              </Card>
            </Row>
          );
        })}
      </Space>
    );
  };

  return (
    <div>
      <h2>Locations</h2>
      {_.isEmpty(merchants) ? <p>loading</p> : renderMerchantInfo(merchants)}
    </div>
  );
};
