import { DeleteTwoTone } from '@ant-design/icons';
import { IDeleteUserRequest, IUser, UserType } from '@swyft/swyft-common';
import { Button, message, Popconfirm, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AddUserButtonModal } from '../../components/modals/AddUserModal';
import { AuthContext } from '../../components/AuthProvider';
import * as FirestoreService from '../../services/firestore';
import { Timestamp } from '@firebase/firestore-types';
import moment from 'moment';
import { GENERIC_ERROR_MESSAGE } from '../../consts';

export const UserManagement: React.FunctionComponent = () => {
  // @ts-ignore
  const { user, merchantGroup } = useContext(AuthContext);

  const [users, setUsers] = useState<IUser[]>([]);
  const [isloading, setLoading] = useState(false);

  const USER_COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a: IUser, b: IUser) =>
          //@ts-ignore
          moment(a.createdAt.toDate()).isBefore(b.createdAt.toDate()) ? 1 : -1,
      },
      defaultSortOrder: 'descend' as any,
      render: (createdAt: Timestamp) => (
        <p>{moment(createdAt.toDate()).format('MMM. D, [at] h:mm a')}</p>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: {
        compare: (a: IUser, b: IUser) =>
          a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: {
        compare: (a: IUser, b: IUser) =>
          a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Actions',
      render: (user: IUser) => (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          onConfirm={() => {
            setLoading(true);
            deleteUser(user);
          }}
        >
          <Button type="default" size="small" icon={<DeleteTwoTone />}>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    getUsers();
  }, [merchantGroup]);

  /**
   * Gets users associated with the merchant or merchant-group
   */
  const getUsers = async () => {
    let users = [] as IUser[];

    switch (user.type) {
      case UserType.MERCHANT:
        users = await FirestoreService.getUsersForMerchantId(user.merchantId);
        break;
      case UserType.MERCHANT_GROUP:
        users = await FirestoreService.getUsersForMerchantGroupId(user.merchantGroupId);
        break;
      default:
        message.error(GENERIC_ERROR_MESSAGE);
        console.log('Error. Unable to determine user type.');
    }

    // Don't include internal users
    users = users.filter((x) => !x.email.endsWith('@useswyft.com'));

    // Don't include inactive (deleted) users
    users = users.filter((x) => !x.hasOwnProperty('deletedAt'));

    // Do not include the current user in the userTable
    setUsers(users.filter((x: IUser) => x.email != user.email));
  };

  const deleteUser = async (user: IUser) => {
    const req: IDeleteUserRequest = { id: user.id };
    await FirestoreService.deleteUser(req);
    setLoading(false);
    message.success(`User Deleted: ${user.email}`);
    getUsers();
  };

  const tableHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingBottom: '0.5em',
        }}
      >
        <AddUserButtonModal onFinish={getUsers} />
      </div>
    );
  };

  return (
    <Table
      columns={USER_COLUMNS}
      dataSource={users}
      rowKey="email"
      size="small"
      loading={isloading}
      title={tableHeader}
    />
  );
};
