import React, { useContext, useEffect, useState, useRef } from 'react';
import { TrackingEventState, ILabelV2, LabelCreationMethod } from '@swyft/swyft-common';
import {
  DocumentSnapshot,
  Timestamp,
  OrderByDirection,
  CollectionReference,
  Query,
  DocumentData,
} from '@firebase/firestore-types';
import {
  Button,
  message,
  Row,
  Space,
  Table,
  DatePicker,
  Tabs,
  Empty,
  Typography,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment-timezone';
import { fulfillExternalOrders, LabelStateTag } from '../helpers/LabelHelpers';
import { exportLabelsPDF } from '../helpers/fileExportHelper';
import { AuthContext } from './AuthProvider';
import { InfoCircleTwoTone, LeftOutlined, LinkOutlined, RightOutlined } from '@ant-design/icons';
import * as FirestoreService from '../services/firestore';
import { withSearch } from '@elastic/react-search-ui';
import { ExportLabelDataModal } from './modals/ExportLabelDataModal';
import { ISearchQuery, SearchQueryType } from './LabelsContainer';
import * as _ from 'lodash';
import { LabelStateModal } from './LabelStateModal';
import { EXPORT_DATE_FORMAT, GENERIC_ERROR_MESSAGE, getTrackingPageUrl } from '../consts';
import { SortOrder } from 'antd/lib/table/interface';

const PAGE_SIZE = 25;

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Link: LinkText, Text } = Typography;

interface PassedProps {
  searchQuery: ISearchQuery;
}

interface IElasticProps {
  isLoading: boolean;
  results: IElasticResult[];
  current: number;
  resultsPerPage: number;
  totalResults: number;
  setSearchTerm: (searchTerm: string) => void;
  setCurrent: (currentPage: number) => void;
  setResultsPerPage: (resultsPerPage?: number) => void;
  resultSearchTerm: string;
  [propName: string]: any;
}

interface IElasticResult {
  id: { raw: string };
}

interface IStateWithFailureReason {
  state: TrackingEventState;
  failureReason: string;
}

interface ILabelWithFailureReason extends ILabelV2 {
  stateWithFailureReason: IStateWithFailureReason;
}

const isDeletable = (label: ILabelV2) => {
  return label.state === TrackingEventState.PENDING;
};

const useSortOrder = <T extends SortOrder>(
  stateArray: T[],
  defaultStateIndex: number,
  clearIndex: number = 2
): [T, () => void, () => void] => {
  const [index, setIndex] = useState(defaultStateIndex);
  const toggleSortOrder = () => {
    setIndex((prev) => (prev + 1) % stateArray.length);
  };

  const clearSortOrder = () => {
    setIndex(clearIndex);
  };

  return [stateArray[index], toggleSortOrder, clearSortOrder];
};

enum DATE_RANGE_TYPE {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  CUSTOM = 'CUSTOM',
  SEARCH = 'SEARCH', // Tab is hidden
}

enum FAILURE_REASON_TYPE {
  NO_ACCESS_COMMERCIAL_ADDRESS = 'No Access-Commercial Address',
  NO_ACCESS_RESIDENTIAL_ADDRESS = 'No Access-Residential Address',
  UNABLE_TO_COLLECT_SIGNATURE = 'Unable to Collect Signature/Verify ID',
  CUSTOMER_REFUSED_DELIVERY = 'Customer Refuses Delivery',
  INCORRECT_CUSTOMER_ADDRESS = 'Incorrect Customer Address',
  MISSING_PACKAGE = 'Missing Package',
  DRIVER_ACCIDENT = 'Driver Accident',
  ITEM_DAMAGED = 'Item Damaged',
  PAST_DELIVERY_WINDOW = 'Past Delivery Window',
  NO_SPACE_IN_CAR = 'No Space In Car',
  OTHER = 'Other',
  UNKNOWN = 'unknown',
}

type Props = IElasticProps & PassedProps;

const LabelsTable: React.FunctionComponent<Props> = ({
  isLoading,
  results,
  current,
  resultsPerPage,
  totalResults,
  setSearchTerm,
  setCurrent,
  setResultsPerPage,
  resultSearchTerm,
  searchQuery,
}) => {
  // @ts-ignore
  const { user, merchant } = useContext(AuthContext);

  const [labels, setLabels] = useState<ILabelV2[]>([]);
  const [labelsWithFailureReason, setLabelsWithFailureReason] = useState<ILabelWithFailureReason[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMoreLabels, setHasMoreLabels] = useState<boolean>(true);
  const [selectedLabels, setSelectedLabels] = useState<ILabelV2[]>([]);
  const [isDeletingLabels, setIsDeletingLabels] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState<boolean>(false);
  const [isLabelStateModalVisible, setIsLabelStateModalVisible] = useState<boolean>(false);
  const [dateRangeType, setDateRangeType] = useState<DATE_RANGE_TYPE>(DATE_RANGE_TYPE.MONTH);
  const [dateRangeStart, setDateRangeStart] = useState<Moment>(moment().startOf('month'));
  const [dateRangeEnd, setDateRangeEnd] = useState<Moment>(moment().endOf('month'));
  const [createdAtSortOrder, toggleCreatedAtSortOrder, clearCreatedAtSortOrder] = useSortOrder(
    ['descend', 'ascend', null],
    0
  );
  const [
    orderNumberSortOrder,
    toggleOrderNumberSortOrder,
    clearOrderNumberSortOrder,
  ] = useSortOrder(['descend', 'ascend', null], 2);
  /**
   * Handle the FuzzySearchBar input (clicking on an autocomplete option or full search)
   * */
  useEffect(() => {
    if (!_.isEmpty(searchQuery)) {
      // Edge case where alternating between exact and full search of the same keyword
      if (searchQuery.keyword === resultSearchTerm) {
        handleSearchByLabelIds(results.map((label: any) => label.id.raw));
      }

      // Full search of a keyword from FuzzySearchBar
      if (searchQuery.type === SearchQueryType.KEYWORD && searchQuery.keyword) {
        setSearchTerm(searchQuery.keyword); // No-op if search term is the same
      }

      // Autocomplete option selected from FuzzySearchBar
      if (searchQuery.type === SearchQueryType.LABEL_ID && searchQuery.labelId) {
        handleSearchByLabelIds([searchQuery.labelId]);
      }
      setDateRangeType(DATE_RANGE_TYPE.SEARCH);
    }
  }, [searchQuery]);

  /**
   * Handles displaying new labels when the ES results change.
   */
  useEffect(() => {
    handleSearchByLabelIds(results.map((label: any) => label.id.raw));
  }, [results]);

  /**
   * Handles displaying new labels when the date range changes
   */
  useEffect(() => {
    fetchLabels();
  }, [user, dateRangeEnd, createdAtSortOrder, orderNumberSortOrder]);

  useEffect(() => {
    switch (dateRangeType) {
      case DATE_RANGE_TYPE.DAY:
        setDateRangeStart(moment());
        setDateRangeEnd(moment());
        break;
      case DATE_RANGE_TYPE.WEEK:
        setDateRangeStart(moment().startOf('week'));
        setDateRangeEnd(moment().endOf('week'));
        break;
      case DATE_RANGE_TYPE.MONTH:
        setDateRangeStart(moment().startOf('month'));
        setDateRangeEnd(moment().endOf('month'));
        break;
      default:
    }
  }, [dateRangeType]);

  const baseQuery = () => {
    let baseQuery:
      | Query<DocumentData>
      | CollectionReference<DocumentData> = FirestoreService.db
      .collection('merchants')
      .doc(user.merchantId)
      .collection('labelsV2');

    if (createdAtSortOrder != null) {
      baseQuery = baseQuery
        .where('createdAt', '>=', dateRangeStart.startOf('day').toDate())
        .where('createdAt', '<=', dateRangeEnd.endOf('day').toDate())
        .orderBy('createdAt', mapSortOrderToOrderDirection(createdAtSortOrder));
    }

    if (orderNumberSortOrder != null) {
      baseQuery = baseQuery.orderBy(
        'orderNumber',
        mapSortOrderToOrderDirection(orderNumberSortOrder)
      );
    }

    return baseQuery.orderBy('id').limit(PAGE_SIZE);
  };

  const mapSortOrderToOrderDirection = (s: SortOrder): OrderByDirection | undefined => {
    if (s === 'ascend') return 'asc';
    return 'desc';
  };

  const fetchLabels = async () => {
    setIsTableLoading(true);
    const res = await baseQuery().get();
    const labels = res.docs.map((docSnapshot: DocumentSnapshot) =>
      docSnapshot.data()
    ) as ILabelV2[];

    const labelsWithFailureReason = await patchFailureReasonToLabels(labels);

    setCurrentPage(0);
    setHasMoreLabels(labels.length === PAGE_SIZE);
    setLabels(labels as any);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setIsTableLoading(false);
  };

  /**
   * Patch failure reason to label if label state is FAILED
   * Otherwise, left failure reason as empty
   */
  const patchFailureReasonToLabels = async (
    labels: ILabelV2[]
  ): Promise<ILabelWithFailureReason[]> => {
    const labelsWithFailureReason: ILabelWithFailureReason[] = [];

    await Promise.all(
      labels.map(async (label) => {
        let failureReason = '';
        if (label.state === TrackingEventState.FAILED) {
          const deliveryAttempt = await FirestoreService.getLatestDeliveryAttemptForLabel(
            label.id,
            label.merchantId
          );

          if (!_.isNil(deliveryAttempt.errorCode)) {
            failureReason = FAILURE_REASON_TYPE[deliveryAttempt.errorCode];
          } else {
            failureReason = FAILURE_REASON_TYPE.UNKNOWN;
          }
        }

        labelsWithFailureReason.push({
          ...label,
          stateWithFailureReason: {
            state: label.state,
            failureReason: failureReason,
          },
        });
      })
    );

    return Promise.resolve(labelsWithFailureReason);
  };

  const handleSearchByLabelIds = async (labelIds: string[]) => {
    setIsTableLoading(true);

    const labels = await Promise.all(
      labelIds.map((id) => FirestoreService.getLabelByMerchantIdAndLabelId(user.merchantId, id))
    );

    const labelsWithFailureReason = await patchFailureReasonToLabels(labels);

    setLabels(labels);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setIsTableLoading(false);
  };

  const handleSetDay = (date: Moment | null) => {
    if (date) {
      setDateRangeStart(date.clone().startOf('day'));
      setDateRangeEnd(date.clone().endOf('day'));
    }
  };

  const handleSetWeek = (date: Moment | null) => {
    if (date) {
      setDateRangeStart(date.clone().startOf('isoWeek'));
      setDateRangeEnd(date.clone().endOf('isoWeek'));
    }
  };

  const handleSetMonth = (date: Moment | null) => {
    if (date) {
      setDateRangeStart(date.clone().startOf('month'));
      setDateRangeEnd(date.clone().endOf('month'));
    }
  };

  const handleSetCustomDateRange = (range: any) => {
    if (range[0] && range[1]) {
      setDateRangeStart(range[0]);
      setDateRangeEnd(range[1]);
    }
  };

  const handlePrevDay = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, 'day'));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, 'day'));
  };

  const handleNextDay = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, 'day'));
    setDateRangeEnd(dateRangeEnd.clone().add(1, 'day'));
  };

  const handlePrevWeek = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, 'week'));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, 'week'));
  };

  const handleNextWeek = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, 'week'));
    setDateRangeEnd(dateRangeEnd.clone().add(1, 'week'));
  };

  const handlePrevMonth = () => {
    setDateRangeStart(dateRangeStart.clone().subtract(1, 'month'));
    setDateRangeEnd(dateRangeEnd.clone().subtract(1, 'month').endOf('month'));
  };

  const handleNextMonth = () => {
    setDateRangeStart(dateRangeStart.clone().add(1, 'month'));
    setDateRangeEnd(dateRangeEnd.clone().add(1, 'month').endOf('month'));
  };

  const handleSetDateRangeType = (type: string) => {
    setDateRangeType(type as DATE_RANGE_TYPE);
    setHasMoreLabels(true);
  };

  const handleNextPage = async () => {
    const lastLabel = labels[labels.length - 1] as ILabelV2;
    const res = await baseQuery().startAfter(lastLabel.createdAt, lastLabel.id).get();
    const fetchedLabels = res.docs.map((docSnapshot: DocumentSnapshot) =>
      docSnapshot.data()
    ) as ILabelV2[];

    const labelsWithFailureReason = await patchFailureReasonToLabels(fetchedLabels);

    if (fetchedLabels.length != 0) {
      setLabels(fetchedLabels as any);
      setLabelsWithFailureReason(labelsWithFailureReason);
      setCurrentPage(currentPage + 1);
    }

    if (fetchedLabels.length < PAGE_SIZE) {
      setHasMoreLabels(false);
    }
  };

  const handlePrevPage = async () => {
    const firstLabel = labels[0] as ILabelV2;
    const res = await baseQuery()
      .endBefore(firstLabel.createdAt, firstLabel.id)
      .limitToLast(PAGE_SIZE)
      .get();
    const newLabels = res.docs.map((docSnapshot: DocumentSnapshot) =>
      docSnapshot.data()
    ) as ILabelV2[];

    const labelsWithFailureReason = await patchFailureReasonToLabels(newLabels);

    setLabels(newLabels as any);
    setLabelsWithFailureReason(labelsWithFailureReason);
    setHasMoreLabels(true);
    setCurrentPage(currentPage - 1);
  };

  const handleDeleteLabels = async (labels: ILabelV2[]) => {
    setIsDeletingLabels(true);
    try {
      await Promise.all(labels.map((label) => FirestoreService.deleteLabel(label.id)));

      message.success('Cancelled label(s)');
    } catch (error) {
      message.error(`Error happened while cancelling labels:  Error: ${error}`);
      console.error(error);
    }
    setSelectedLabels([]);
    setIsDeletingLabels(false);
  };

  const handleExportLabels = async (labels: ILabelV2[]) => {
    setIsTableLoading(true);
    try {
      const { shipDate } = labels[0];
      const formattedDate = moment(shipDate).format(EXPORT_DATE_FORMAT);
      const fileName = `${formattedDate}_labels.pdf`;

      await exportLabelsPDF(labels, fileName, true);
      fulfillExternalOrders(labels, merchant);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsTableLoading(false);
  };

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const hideExportModal = () => {
    setIsExportModalVisible(false);
  };

  const hideLabelStateModal = () => {
    setIsLabelStateModalVisible(false);
  };
  const showLabelStateModal = () => {
    setIsLabelStateModalVisible(true);
  };

  const labelColumns = () => {
    const columnsTemplate = [
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (createdAt: Timestamp) => (
          <p>{moment(createdAt.toDate()).format('MMM. D, [at] h:mm a')}</p>
        ),
        sortOrder: createdAtSortOrder,
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              clearOrderNumberSortOrder();
              toggleCreatedAtSortOrder();
            },
          };
        },
      },
      {
        title: 'Ship Date',
        dataIndex: 'shipDate',
        render: (shipDate: string) => <p>{moment(shipDate).format('MMM. DD')}</p>,
      },
      {
        title: 'Customer',
        dataIndex: 'destination',
        render: (destination: any) => (
          <p>
            {destination.firstName} {destination.lastName}
          </p>
        ),
      },
      {
        title: 'Address',
        dataIndex: ['destination', 'address'],
        render: (address: any) => (
          <p>
            {address.line1}, {address.line2 ? `${address.line2}, ` : ''}
            {address.city}, {address.province} {address.postalCode}
          </p>
        ),
      },
      {
        title: 'Phone',
        dataIndex: ['destination', 'phone'],
      },
      {
        title: 'Email',
        dataIndex: ['destination', 'email'],
      },
      {
        title: 'Creation Method',
        dataIndex: 'creationMethod',
        render: (creationMethod: string) => {
          // Change to title-case unless creation ethod is API
          if (creationMethod) {
            if (creationMethod !== LabelCreationMethod.API) {
              const split = creationMethod.split('_');
              return split.map((c) => c.charAt(0) + c.toLowerCase().slice(1)).join(' ');
            } else {
              return creationMethod;
            }
          }
        },
      },
      {
        title: 'Order #',
        align: 'center' as any,
        dataIndex: 'orderNumber',
        sortOrder: orderNumberSortOrder,
        sorter: true,
        onHeaderCell: () => {
          return {
            onClick: () => {
              clearCreatedAtSortOrder();
              toggleOrderNumberSortOrder();
            },
          };
        },
      },
      {
        title: (
          <Space>
            <Text>State</Text>
            <InfoCircleTwoTone onClick={showLabelStateModal} />
          </Space>
        ),
        dataIndex: 'stateWithFailureReason',
        align: 'center' as any,
        render: (stateWithFailureReason: IStateWithFailureReason) => (
          <div style={{ textAlign: 'center' }}>
            <LabelStateTag
              state={stateWithFailureReason.state}
              failureReason={stateWithFailureReason.failureReason}
            />
          </div>
        ),
      },
      {
        title: 'Tracking Link',
        dataIndex: 'trackingNumber',
        align: 'center' as any,
        render: (trackingNumber: string) => (
          <div style={{ textAlign: 'center' }}>
            <LinkText
              /** @todo Handle staging */
              href={getTrackingPageUrl(trackingNumber)}
              target="_blank"
            >
              <LinkOutlined />
            </LinkText>
          </div>
        ),
      },
      {
        title: 'Action',
        align: 'center' as any,
        render: (_text: string, label: ILabelV2) => (
          <>
            <Button type="link" onClick={() => handleExportLabels([label])}>
              Print
            </Button>
            <Button
              danger
              type="link"
              onClick={() => handleDeleteLabels([label])}
              disabled={!isDeletable(label)}
            >
              Cancel
            </Button>
          </>
        ),
      },
    ];

    if (!_.isNil(merchant.shopifyConfig)) {
      const shopifyOrderNumberColumn = {
        title: 'Shopify Order #',
        align: 'center' as any,
        dataIndex: 'shopifyOrderNumber',
      };
      // @ts-ignore
      columnsTemplate.splice(7, 0, shopifyOrderNumberColumn);
    }

    return columnsTemplate;
  };

  const tableHeader = () => {
    return (
      <Row justify="space-between" style={{ paddingBottom: '10px' }}>
        <Tabs activeKey={dateRangeType} onChange={handleSetDateRangeType}>
          <TabPane tab="Day" key={DATE_RANGE_TYPE.DAY}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevDay} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                format={'MMMM Do, YYYY'}
                onChange={handleSetDay}
                picker="date"
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextDay} />
            </Row>
          </TabPane>
          <TabPane tab="Week" key={DATE_RANGE_TYPE.WEEK}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevWeek} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                onChange={handleSetWeek}
                picker="week"
                format={'MMMM Do, YYYY'}
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextWeek} />
            </Row>
          </TabPane>
          <TabPane tab="Month" key={DATE_RANGE_TYPE.MONTH}>
            <Row>
              <Button icon={<LeftOutlined />} onClick={handlePrevMonth} />
              <DatePicker
                style={{ flex: 1 }}
                value={dateRangeStart}
                format={'MMMM, YYYY'}
                onChange={handleSetMonth}
                picker="month"
                allowClear={false}
              />
              <Button icon={<RightOutlined />} onClick={handleNextMonth} />
            </Row>
          </TabPane>
          <TabPane tab="Range" key={DATE_RANGE_TYPE.CUSTOM}>
            <RangePicker
              value={[dateRangeStart, dateRangeEnd]}
              format={'MMM. Do, YYYY'}
              onCalendarChange={handleSetCustomDateRange}
              allowClear={false}
            />
          </TabPane>
        </Tabs>

        <Space direction="horizontal">
          <Button type="primary" onClick={showExportModal}>
            Export or Print Selected {selectedLabels.length > 0 && `(${selectedLabels.length})`}
          </Button>
          <Tooltip placement="bottomRight" title="Only labels in state PENDING can be cancelled.">
            <Button
              type="primary"
              danger
              loading={isDeletingLabels}
              disabled={selectedLabels.filter(isDeletable).length < 1}
              onClick={() => handleDeleteLabels(selectedLabels.filter(isDeletable))}
            >
              Cancel
              {selectedLabels.filter(isDeletable).length > 0 &&
                `(${selectedLabels.filter(isDeletable).length})`}
            </Button>
          </Tooltip>
        </Space>
        <ExportLabelDataModal
          selectedLabels={selectedLabels}
          isModalVisible={isExportModalVisible}
          hideModal={hideExportModal}
          merchantId={user.merchantId}
        />
      </Row>
    );
  };

  // Pagination for Day, Week, Month, Custom date ranges
  const tableFooter = () => {
    if (dateRangeType !== DATE_RANGE_TYPE.SEARCH) {
      return (
        <Row justify="center">
          <Button
            icon={<LeftOutlined />}
            disabled={currentPage === 0}
            onClick={handlePrevPage}
          ></Button>
          <Button
            icon={<RightOutlined />}
            disabled={hasMoreLabels === false || labels.length === 0}
            onClick={handleNextPage}
          />
        </Row>
      );
    }
  };

  // Pagination for Search
  const paginationConfiguration =
    dateRangeType === DATE_RANGE_TYPE.SEARCH
      ? {
          onChange: (page: number, pageSize?: number) => {
            setResultsPerPage(pageSize);
            setCurrent(page);
          },
          total: totalResults,
          current,
          pageSize: resultsPerPage,
        }
      : false;

  const onSelectTableRow = (_selectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedLabels(selectedRows);
  };

  const labelColumInstances = labelColumns();

  return (
    <>
      <Table
        rowKey="id"
        style={{ height: '100%', width: '100%' }}
        dataSource={labelsWithFailureReason}
        columns={labelColumInstances}
        size="small"
        loading={isTableLoading || isLoading}
        title={tableHeader}
        footer={tableFooter}
        pagination={paginationConfiguration}
        scroll={{ x: true }}
        rowSelection={{
          type: 'checkbox',
          onChange: onSelectTableRow,
        }}
        locale={{
          emptyText: (
            <Empty style={{ margin: '6em', color: 'grey' }} description="No labels for date range">
              <Button>
                <Link to="/">Create a label</Link>
              </Button>
            </Empty>
          ),
        }}
      />
      <LabelStateModal hideModal={hideLabelStateModal} isVisible={isLabelStateModalVisible} />
    </>
  );
};

// withSearch is an HOC for accessing the headless core directly.
export default withSearch(
  ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  }: Props) => ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  })
)(LabelsTable);
