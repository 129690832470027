import { isEmpty } from 'lodash';
import { CSV_TEMPLATE_COLUMNS } from '../../consts';
import { ICsvTemplateRow } from '../../types';

export interface IClientCsvErrorMap {
  [rowNum: string]: string[];
}

export const validateRows = (rows: ICsvTemplateRow[]) => {
  let errors: IClientCsvErrorMap = {};
  rows.forEach((row, index) => {
    const rowNum = index + 1;

    // Check for required fields
    const missingFields = getMissingFields(row);
    if (!isEmpty(missingFields)) {
      (errors[rowNum] = errors[rowNum] || []).push(
        `Required fields are missing: ${missingFields.join(' ,')}.`
      );
    }

    // Check for phone OR email
    if (!hasPhoneOrEmail(row)) {
      (errors[rowNum] = errors[rowNum] || []).push(
        `Recipient phone number and email address are missing. At least one is required.`
      );
    }

    // Validate number fields
    const malformedNumberFields = getMalformedNumberFields(row);
    if (!isEmpty(malformedNumberFields)) {
      (errors[rowNum] = errors[rowNum] || []).push(
        `Fields should be numbers only: ${malformedNumberFields.join(' ,')}.`
      );
    }
  });

  return errors;
};

const getMissingFields = (row: ICsvTemplateRow) => {
  let missing: string[] = [];

  CSV_TEMPLATE_COLUMNS.forEach((col) => {
    if (
      col.required &&
      //@ts-ignore
      (!Object.keys(row).includes(col.dataIndex) || isEmpty(row[col.dataIndex]))
    ) {
      missing.push(col.dataIndex);
    }
  });

  return missing;
};

const hasPhoneOrEmail = (row: ICsvTemplateRow) => {
  return (row.Phone && !isEmpty(row.Phone)) || (row.Email && !isEmpty(row.Email));
};

const getMalformedNumberFields = (row: ICsvTemplateRow) => {
  let malformed: string[] = [];

  if (row.Package_Weight_LB && isNaN(Number(row.Package_Weight_LB))) {
    malformed.push('Package_Weight_LB');
  }

  if (row.Length_IN && isNaN(Number(row.Length_IN))) {
    malformed.push('Length_IN');
  }

  if (row.Width_IN && isNaN(Number(row.Width_IN))) {
    malformed.push('Width_IN');
  }

  if (row.Height_IN && isNaN(Number(row.Height_IN))) {
    malformed.push('Height_IN');
  }

  return malformed;
};
