import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Header } from '../components/Header';
import { Sidebar } from '../components/Sidebar';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import { Loading } from './Loading';
import { Labels } from './merchant/Labels';
import { Create } from './merchant/Create';
import { Overview } from './merchant-group/Overview';
import * as _ from 'lodash';
import { AuthContext } from '../components/AuthProvider';
import { MultiMerchantLabels } from './merchant-group/MultiMerchantLabels';
import { isShopifyContext } from '../services/shopify';
import { Settings } from './merchant/Settings';
import { UserManagement } from './common/UserManagement';
import { UserMisconfiguredCard } from '../components/UserMisconfiguredCard';
import { Reports } from './common/Reports';

const { Content } = Layout;

export const Home: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isLoadingContext, setIsLoadingContext] = useState(true);

  //@ts-ignore
  const { user, merchant, merchantGroup } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      setIsLoadingContext(false);
    }
  }, [user, merchant, merchantGroup]);

  const onSidebarCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  function renderContent() {
    return (
      <Content
        style={{
          padding: 24,
          minHeight: 'fit-content',
          background: 'white',
        }}
      >
        {isLoadingContext ? (
          <Loading />
        ) : (
          <Switch>
            {!_.isEmpty(merchant) && (
              <>
                <PrivateRoute path={'/labels'} exact>
                  <Labels />
                </PrivateRoute>
                {user.permissions?.includes('ADMIN') && (
                  <PrivateRoute path={'/users'} exact>
                    <UserManagement />
                  </PrivateRoute>
                )}
                <PrivateRoute path={'/settings'} exact>
                  <Settings />
                </PrivateRoute>
                {!_.isNil(merchant.reportURL) && (
                  <PrivateRoute path={'/reports'} exact>
                    <Reports reportURL={merchant.reportURL} />
                  </PrivateRoute>
                )}
                <PrivateRoute path={'/'} exact>
                  <Create />
                </PrivateRoute>
              </>
            )}
            {!_.isEmpty(merchantGroup) && (
              <>
                <PrivateRoute path={'/labels'} exact>
                  <MultiMerchantLabels />
                </PrivateRoute>
                {user.permissions?.includes('ADMIN') && (
                  <PrivateRoute path={'/users'} exact>
                    <UserManagement />
                  </PrivateRoute>
                )}
                {!_.isNil(merchantGroup.reportURL) && (
                  <PrivateRoute path={'/reports'} exact>
                    <Reports reportURL={merchantGroup.reportURL} />
                  </PrivateRoute>
                )}
                <PrivateRoute path={'/'} exact>
                  <Overview />
                </PrivateRoute>
              </>
            )}
            {!_.isEmpty(user) && _.isEmpty(merchantGroup) && _.isEmpty(merchant) && (
              <UserMisconfiguredCard />
            )}
          </Switch>
        )}
      </Content>
    );
  }

  if (isShopifyContext()) {
    return renderContent();
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Header isSidebarCollapsed={isSidebarCollapsed} />
      <Layout>
        <Sidebar isSidebarCollapsed={isSidebarCollapsed} onSidebarCollapse={onSidebarCollapse} />
        <Layout style={{ padding: 30, overflow: 'scroll' }}>{renderContent()}</Layout>
      </Layout>
    </Layout>
  );
};
