/**
 * Each error needs to set the prototype explicitly. Needed because of TS breaking change:
 * https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
 */

export class InvalidPackageDimensionsError extends Error {
  /**
   * Error when provided package dimensions IDims fails validation
   * @param message Generic message of the error
   */
  constructor(message: string) {
    super(message);

    this.name = 'InvalidPackageDimensionsError';
    Object.setPrototypeOf(this, InvalidPackageDimensionsError.prototype);
  }
}
