import * as _ from 'lodash';
import * as FirestoreService from '../services/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { Badge, Col, Form, Input, Row, Switch, Select, Space, Card, Button, message } from 'antd';
import { ResourcePicker } from '@shopify/app-bridge-react';
import { BaseResource, Product } from '@shopify/app-bridge/actions/ResourcePicker';
import {
  IExcludedItem,
  IMerchant,
  IShopifyDiscountSettings,
  IShopifyDiscountType,
} from '@swyft/swyft-common';
import { COMPANY_NAME, GENERIC_ERROR_MESSAGE } from '../consts';

interface IShopifyCheckoutForm {
  shippingPriceDollars: number;
}

interface IShopifyDiscountForm {
  discountEnabled: boolean;
  cartValueThresholdCents: number;
  discountAmount: number;
}

interface IShopifyExcludedItemsSetttings {
  excludedItemsEnabled: boolean;
}

interface ProductSelectPayload {
  readonly id?: string;
  selection: Product[];
}

/**
 * Helper function which parses an item's ID from the database
 * into the form accepted by shopify ResourcePicker.
 * The itemIDs come in the form {itemID}/{VariantId}
 * and shopify itemIds are of the form
 * gid://shopify/{ProductType}/{productId}
 *
 * @param itemIdentifier the shopify identifier (path) of the item
 * @returns BaseResource object representing the item
 */
const parseFirestoreItemId = (itemIdentifier: string): BaseResource => {
  const itemIdParts = itemIdentifier.split('/');
  return {
    id: `gid://shopify/Product/${itemIdParts[0]}`,
    variants: [{ id: `gid://shopify/ProductVariant/${itemIdParts[1]}` }],
  };
};

/**
 * Parse a ProductSelectPayload object into a list of itemIds of the form
 * {itemID}/{VariantId} to store in the database.
 *
 * The ids in selectPayload come in the from gid://shopify/Product/{productId}
 * @param selectPayload
 */
const parseSelectPayload = (selectPayload: ProductSelectPayload) => {
  let selectedIds = [];
  for (const selectedProduct of selectPayload.selection) {
    const productId = selectedProduct.id.split('/');
    for (const productVariant of selectedProduct.variants) {
      if (productVariant.id) {
        selectedIds.push(`${productId[4]}/${productVariant.id.split('/').pop()}`);
      } else {
        throw new Error('Malformed select payload object');
      }
    }
  }
  return selectedIds;
};

/**
 *  React component representing Shopify Settings tab
 */
export const ShopifySettings: React.FC = () => {
  // @ts-ignore
  const { merchant: ctxMerchant, user } = useContext(AuthContext);

  const [checkoutForm] = Form.useForm<IShopifyCheckoutForm>();
  const [discountForm] = Form.useForm<IShopifyDiscountForm>();
  const [excludedItemsForm] = Form.useForm<IShopifyExcludedItemsSetttings>();
  const [merchant, setMerchant] = useState<IMerchant>(ctxMerchant);

  // Current excluded items fetched from firestore
  const [fetchedExcludedItems, setFetchedExcludedItems] = useState<BaseResource[]>([]);

  const [isExcludedItemsEnabledLoading, setIsExcludedItemsEnabledLoading] = useState(false);
  const [isExcludedItemsLoading, setIsExcludedItemsLoading] = useState(false);
  const [isDiscountSettingsLoading, setIsDiscountSettingsLoading] = useState(false);
  const [isCheckoutEnabledLoading, setIsCheckoutEnabledLoading] = useState(false);
  const [isCheckoutSettingsLoading, setIsCheckoutSettingsLoading] = useState(false);
  const [isResourcePickerOpen, setIsResourcePickerOpen] = useState(false);

  // Cart discount type
  const [discountType, setDiscountType] = useState(IShopifyDiscountType.PERCENTAGE);

  useEffect(() => {
    if (!_.isEmpty(merchant)) {
      setCheckoutFormDefaults();
      setDiscountFormDefaults();
      setExcludedItemsFormDefaults();
    }
  }, [merchant]);

  useEffect(() => {
    getMerchant().catch((err) => console.error(err));
  }, []);

  const getMerchant = async () => {
    setIsCheckoutSettingsLoading(true);

    const getMerchantReq = await FirestoreService.getMerchant(user.merchantId);
    setMerchant(getMerchantReq.data() as IMerchant);
    getExcludedItemsForMerchant();
    setIsCheckoutSettingsLoading(false);
  };

  const setCheckoutFormDefaults = () => {
    if (merchant?.shopifyConfig) {
      const { shippingPriceCents } = merchant?.shopifyConfig;

      checkoutForm.setFieldsValue({
        shippingPriceDollars: shippingPriceCents / 100,
      });
    } else {
      checkoutForm.setFieldsValue({
        shippingPriceDollars: 5,
      });
    }
  };

  const resetCheckoutForm = () => {
    checkoutForm.resetFields();
    setCheckoutFormDefaults();
  };

  const setDiscountFormDefaults = () => {
    const { shopifyConfig } = merchant;

    if (shopifyConfig?.cartValueDiscountSettings) {
      const {
        cartValueDiscountSettings: {
          discountEnabled,
          cartValueThresholdCents,
          discountType: defaultDiscountType,
          discountAmount,
        },
      } = shopifyConfig;

      setDiscountType(defaultDiscountType || IShopifyDiscountType.PERCENTAGE);

      discountForm.setFieldsValue({
        discountEnabled,
        cartValueThresholdCents: cartValueThresholdCents / 100,
        discountAmount:
          defaultDiscountType === IShopifyDiscountType.PERCENTAGE
            ? discountAmount * 100
            : discountAmount / 100,
      });
    } else {
      setDiscountType(IShopifyDiscountType.PERCENTAGE);

      discountForm.setFieldsValue({
        discountEnabled: false,
        cartValueThresholdCents: 0,
        discountAmount: 0,
      });
    }
  };

  const resetDiscountForm = () => {
    discountForm.resetFields();
    setDiscountFormDefaults();
  };

  const setExcludedItemsFormDefaults = () => {
    const { shopifyConfig } = merchant;

    if (shopifyConfig) {
      const { excludedItemsEnabled } = shopifyConfig;
      excludedItemsForm.setFieldsValue({
        excludedItemsEnabled,
      });
    } else {
      excludedItemsForm.setFieldsValue({
        excludedItemsEnabled: false,
      });
    }
  };

  const saveShippingPrice = async ({ shippingPriceDollars }: IShopifyCheckoutForm) => {
    setIsCheckoutSettingsLoading(true);
    try {
      const shippingPriceCents = shippingPriceDollars * 100;

      await FirestoreService.updateShopifyShippingPrice(shippingPriceCents);

      await getMerchant();

      message.success('Settings Updated');
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }

    setIsCheckoutSettingsLoading(false);
  };

  /**
   * Update the checkoutEnabled flag
   * @param checkoutEnabled
   */
  const saveCheckoutEnabled = async (checkoutEnabled: boolean, _event: MouseEvent) => {
    setIsCheckoutEnabledLoading(true);
    try {
      await FirestoreService.updateShopifyCheckoutConfig(checkoutEnabled);
      await getMerchant();
      message.success(`Checkout ${checkoutEnabled ? 'enabled' : 'disabled'}!`);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }

    setIsCheckoutEnabledLoading(false);
  };

  /**
   * Process the user's input in the DiscountForm.
   * @param discountEnabled
   * @param cartValueDiscountAmountCents
   * @param cartValueDiscountInput
   */
  const saveDiscountSettings = async (discountForm: IShopifyDiscountForm) => {
    setIsDiscountSettingsLoading(true);

    try {
      const { discountEnabled, cartValueThresholdCents, discountAmount } = discountForm;
      const { shopifyConfig } = merchant;

      // Format dollar amounts to be in cents or percentage into a number [0-1]
      let formattedDiscountAmount: number = 0;

      const formattedDiscountThresholdAmount = cartValueThresholdCents * 100;

      if (discountType === IShopifyDiscountType.PERCENTAGE) {
        // If cartValueDiscountType is PERCENTAGE then the user entered a percent [0-100]
        formattedDiscountAmount = discountAmount / 100;

        if (formattedDiscountAmount < 0 || formattedDiscountAmount > 1) {
          message.error('Percentage discount must be a number between 0 and 100');
          setIsDiscountSettingsLoading(false);
          return;
        }
      } else if (discountType === IShopifyDiscountType.FLAT) {
        // If cartValueDiscountType is FLAT then the user entered a dollar amount
        formattedDiscountAmount = discountAmount * 100;
        // Validation to make sure discount amount is less than base shipping price
        if (shopifyConfig && formattedDiscountAmount >= shopifyConfig?.shippingPriceCents) {
          message.error('Flat discount amount cannot be greater than the shipping price');
          setIsDiscountSettingsLoading(false);
          return;
        }
        if (formattedDiscountAmount < 0) {
          message.error('Flat discount amount must be a positive number');
          setIsDiscountSettingsLoading(false);
          return;
        }
      }

      const updatedDiscountSettings: IShopifyDiscountSettings = {
        discountEnabled,
        discountType,
        cartValueThresholdCents: formattedDiscountThresholdAmount,
        discountAmount: formattedDiscountAmount,
      };

      await FirestoreService.updateShopifyDiscountConfig(updatedDiscountSettings);
      await getMerchant();
      message.success('Settings Updated');
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }
    setIsDiscountSettingsLoading(false);
  };

  /**
   * Update the excludedItemsEnabled flag
   * @param excludedItemsEnabled
   */
  const saveExcludedItemsSettings = async (excludedItemsEnabled: boolean, _event: MouseEvent) => {
    setIsExcludedItemsEnabledLoading(true);
    try {
      await FirestoreService.updateShopifyExcludedItemsConfig(excludedItemsEnabled);
      await getMerchant();
      message.success(`Item exclusion ${excludedItemsEnabled ? 'enabled' : 'disabled'}!`);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }

    setIsExcludedItemsEnabledLoading(false);
  };

  const saveExcludedItemsSelection = async (payload: ProductSelectPayload) => {
    setIsExcludedItemsLoading(true);
    try {
      const selectedIds = parseSelectPayload(payload);
      await FirestoreService.updateExcludedItems(merchant.id, selectedIds);
      await getMerchant();
      message.success('Item Exclusion List has been updated!');
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }

    setIsExcludedItemsLoading(false);
    setIsResourcePickerOpen(false);
  };

  const getExcludedItemsForMerchant = async () => {
    const excludedItemsForMerchant = await FirestoreService.getExcludedItemsForMerchantId(
      merchant.id
    );
    setFetchedExcludedItems(
      excludedItemsForMerchant.map((item: IExcludedItem) => parseFirestoreItemId(item.productId))
    );
  };

  const discountTypeSelector = (
    <Select value={discountType} onChange={(value: IShopifyDiscountType) => setDiscountType(value)}>
      <Select.Option value={IShopifyDiscountType.PERCENTAGE}>Percentage</Select.Option>
      <Select.Option value={IShopifyDiscountType.FLAT}>Flat Amount</Select.Option>
    </Select>
  );

  return (
    <Space direction="vertical">
      <Card title="Shopify Checkout Settings">
        <Form form={checkoutForm} layout="vertical" onFinish={saveShippingPrice}>
          <Row>
            <Form.Item
              label={`${COMPANY_NAME} Rate Displayed During Checkout`}
              name="checkoutEnabled"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="enabled"
                unCheckedChildren="disabled"
                defaultChecked={merchant?.shopifyConfig?.checkoutEnabled || false}
                loading={isCheckoutEnabledLoading}
                onChange={saveCheckoutEnabled}
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Shipping Price" name="shippingPriceDollars">
              <Input disabled={isCheckoutSettingsLoading} placeholder="5.00" prefix="$" />
            </Form.Item>
          </Row>
          <Form.Item>
            <Space>
              <Button loading={isCheckoutSettingsLoading} type="primary" htmlType="submit">
                Save
              </Button>
              <Button type="default" onClick={resetCheckoutForm}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Discounted Shipping Settings">
        <Form form={discountForm} layout="vertical" onFinish={saveDiscountSettings}>
          <Row>
            <Form.Item
              label="Discounted Shipping based on Cart Value"
              name="discountEnabled"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="enabled"
                unCheckedChildren="disabled"
                loading={isDiscountSettingsLoading}
              />
            </Form.Item>
          </Row>
          <Row gutter={24}>
            <Col>
              <Form.Item label="Minimum Cart Value" name="cartValueThresholdCents">
                <Input
                  type="number"
                  step=".01"
                  disabled={isDiscountSettingsLoading}
                  placeholder="100.00"
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={`${
                  discountType === IShopifyDiscountType.PERCENTAGE ? 'Percent' : 'Flat'
                } Discount Applied`}
                name="discountAmount"
              >
                <Input
                  min={0}
                  type="number"
                  step=".01"
                  addonBefore={discountTypeSelector}
                  addonAfter={discountType === IShopifyDiscountType.FLAT ? '$' : '%'}
                  disabled={isDiscountSettingsLoading}
                  placeholder="0"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space>
              <Button loading={isDiscountSettingsLoading} type="primary" htmlType="submit">
                Save
              </Button>
              <Button type="default" onClick={resetDiscountForm}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Card title="Excluded Items Settings">
        <Form form={excludedItemsForm} layout="vertical">
          <Row>
            <Form.Item
              label={`Exclude Certain Products from ${COMPANY_NAME} Shipping`}
              name="excludedItemsEnabled"
              valuePropName="checked"
            >
              <Switch
                checkedChildren="enabled"
                unCheckedChildren="disabled"
                loading={isExcludedItemsEnabledLoading}
                onChange={saveExcludedItemsSettings}
              />
            </Form.Item>
          </Row>
          <Row gutter={24}>
            <ResourcePicker
              resourceType="Product"
              open={isResourcePickerOpen}
              selectMultiple={true}
              onSelection={saveExcludedItemsSelection}
              onCancel={() => setIsResourcePickerOpen(false)}
              initialSelectionIds={fetchedExcludedItems}
            />
          </Row>
          <Form.Item>
            <Space>
              <Badge count={fetchedExcludedItems.length} showZero>
                <Button
                  type="primary"
                  loading={isExcludedItemsLoading}
                  onClick={() => setIsResourcePickerOpen(true)}
                >
                  Add/Remove Items
                </Button>
              </Badge>
            </Space>
          </Form.Item>
        </Form>
      </Card>
    </Space>
  );
};
