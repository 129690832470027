import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

const projectId = process.env.REACT_APP_FB_PROJECT_ID;

let engineName: string;

if (projectId === 'deep-thought-swyft') {
  engineName = 'labels-prod';
}
if (projectId === 'deep-thought-swyft-staging') {
  engineName = 'labels-staging';
}
if (projectId === 'deepthought-swyft-dev') {
  engineName = 'labels-dev';
}

export const SEARCH_CONFIGURATION = {
  /**
   *  When searching as we type, it can be useful to "debounce" search requests to avoid creating an excessive number of requests.
   *  This controls the length with ms to debounce / wait.
   */
  debounce: 200,
  /**
   * Refresh main search results on keyword update
   */
  refresh: false,
};
/**
 * The number of results to show in the autocomplete dropdown.
 */
const AUTOCOMPLETE_RESULTS_LENGTH = 5;

/**
 * Character length of the snippet returned. Must be at least 20.
 */
const SNIPPET_SIZE = 100;

/**
 * If true, return the raw text field if no snippet is found. If false, only use snippets.
 */
const SNIPPET_FALLBACK = true;

/**
 * This token is valid however it will not return any search results
 */
export const NULL_SEARCH_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWx0ZXJzIjp7ImFsbCI6W3sibWVyY2hhbnRfaWQiOiIifV19LCJhcGlfa2V5X25hbWUiOiJyZWFkLW9ubHktcHJpdmF0ZS1rZXkiLCJpYXQiOjE2MTY2OTQzNDl9.Ygm5EofINlEqT6ebIoTjvrodbQhPtWnI2z_x-kWQriE';

/**
 * This Connector is used to make calls to Elastic's App Search API
 * @param searchToken
 */
const connector = (searchToken: string) =>
  new AppSearchAPIConnector({
    /**
     * public read-only search key
     */
    searchKey: searchToken,
    engineName,
    endpointBase: 'https://cfa92e19a8d9457f8e0a544a38e0a8cc.ent-search.us-central1.gcp.cloud.es.io',
  });

/**
 * @param searchToken
 * @returns Configuration options
 */
export const configuration = (searchToken: string) => {
  return {
    apiConnector: connector(searchToken),
    /**
     * Configuration options for the main search query
     */
    searchQuery: {
      result_fields: { id: { raw: {} } },
      sort: [{ _score: 'desc' }, { created_at: 'desc' }],
    },
    /**
     * Configuration options for the autocomplete search query
     */
    autocompleteQuery: {
      /**
       * Customize the query for autocompleteResults
       */
      results: {
        /**
         * # of suggested labels in autocomplete dropdown
         */
        resultsPerPage: AUTOCOMPLETE_RESULTS_LENGTH,
        sort: [{ _score: 'desc' }, { created_at: 'desc' }],
        result_fields: {
          created_at: { raw: {} },
          ship_date: { raw: {} },
          first_name: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          last_name: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          phone: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          line1: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          line2: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          city: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          province: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          postal_code: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK } },
          shopify_order_id: {
            snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK },
            raw: {},
          },
          shopify_order_number: {
            snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK },
            raw: {},
          },
          tracking_number: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK }, raw: {} },
          order_number: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK }, raw: {} },
          email: { snippet: { size: SNIPPET_SIZE, fallback: SNIPPET_FALLBACK }, raw: {} },
          state: { raw: {} },
        },
      },
    },
  };
};
