import firebase from 'firebase';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { GENERIC_ERROR_MESSAGE } from '../consts';
import { isRebrandEnabled } from '@swyft/swyft-common/lib/consts';

interface IPasswordResetFormValues {
  email: string;
}

export const PasswordReset: React.FunctionComponent = () => {
  const isLoggedIn = Boolean(firebase.auth().currentUser);

  const resetPassword = (values: IPasswordResetFormValues) => {
    const { email } = values;

    // PUBLIC_URL isn't set in the development environment
    const action = process.env.PUBLIC_URL ? { url: `${process.env.PUBLIC_URL}/login` } : null;

    firebase
      .auth()
      .sendPasswordResetEmail(email, action)
      .then((_user) => {
        message.success(
          'A password reset email has been sent. Please check your spam folder as well'
        );
      })
      .catch((error) => {
        console.error(error);
        message.error(GENERIC_ERROR_MESSAGE);
      });
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Row style={{ height: '90vh' }} justify="center" align="middle">
      <Col span={7}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3em' }}>
          {isRebrandEnabled ? (
            <img src="/hero-helo.png" width={256} />
          ) : (
            <img src="/hero.png" width={314} height={122} />
          )}
        </div>
        <Form name="basic" initialValues={{ remember: true }} onFinish={resetPassword}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
            <Input prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Reset Password
            </Button>
          </Form.Item>
          <Row justify="end">
            <Link to="/login">Back to login</Link>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
