import * as FirestoreService from './firestore';
import createApp, { AppConfigV2, ClientApplication } from '@shopify/app-bridge';
import { getSessionToken as shopifyGetSessionToken } from '@shopify/app-bridge-utils';
import { ActionCallback, AppLink, NavigationMenu } from '@shopify/app-bridge/actions';

const SWYFT_PUBLIC_APP_API_KEY =
  process.env.REACT_APP_SHOPIFY_API_KEY || '80dbb85f03a11a467d6e351e42863502';
const REACT_APP_SHOPIFY_REDIRECT_URL = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
const SHOPIFY_OAUTH_SCOPES = process.env.REACT_APP_SHOPIFY_OAUTH_SCOPES;

const urlParams = new URLSearchParams(window.location.search);

const redirectUri = REACT_APP_SHOPIFY_REDIRECT_URL;
const shop = urlParams.get('shop') || '';
const permissionUrl = (key: string) => {
  return `https://${shop}/admin/oauth/authorize?client_id=${key}&scope=${SHOPIFY_OAUTH_SCOPES}&redirect_uri=${redirectUri}`;
};

const host = urlParams.get('host') || '';

let config: AppConfigV2;
let app: ClientApplication<any>;

export function isShopifyContext() {
  return !!shop;
}

export function getShopifyConfig(): AppConfigV2 {
  return config;
}

let callback = () => {};

const isMerchantUsingCustomApp = (shop: string) => {
  if (
    shop === 'marsatta-chocolates.myshopify.com' ||
    shop === 'umoro.myshopify.com' || // ShipApollo Test Account
    shop === 'alphaleteca.myshopify.com' ||
    shop === 'buffbunnyca.myshopify.com' ||
    shop === 'wakewater.myshopify.com' ||
    shop === 'breadbar.myshopify.com' ||
    // Staging store for mastermind
    shop === 'mastermind-toys.myshopify.com' ||
    shop === 'minimale-animale.myshopify.com' ||
    shop === 'bulldognutrition.myshopify.com' ||
    shop === 'swyft-demo.myshopify.com' ||
    shop === 'mysupplements-ca.myshopify.com' ||
    shop === 'archivestoronto.myshopify.com' ||
    shop === 'peace-collective.myshopify.com' ||
    shop === 'incensepro-com.myshopify.com' ||
    shop === 'spasupply.myshopify.com' ||
    shop === 'therapysupply.myshopify.com' ||
    shop === 'jenny-bird-canada.myshopify.com' ||
    shop === 'kotn-ss15.myshopify.com' ||
    shop === 'gee-beauty-toronto.myshopify.com' ||
    shop === '6bygeebeauty.myshopify.com' ||
    shop === 'peoples-choice-beef-jerky.myshopify.com' ||
    shop === 'mayana-geneviere.myshopify.com' ||
    shop === 'mindzai.myshopify.com'
  ) {
    return true;
  } else {
    return false;
  }
};

export async function shopifyInit() {
  // Already setup
  if (app) return;

  // Is root level window?
  if (window.top == window.self) {
    // Initial install of the app?
    if (!host && shop) {
      try {
        // Is the merchant using ShopifyV2 via the legacy Custom App flow?
        if (isMerchantUsingCustomApp(shop)) {
          window.location.assign(permissionUrl(await FirestoreService.getShopifyKeyForShop(shop)));
        } else {
          // Merchant is installing via the publicly available app
          window.location.assign(permissionUrl(SWYFT_PUBLIC_APP_API_KEY));
        }
      } catch (err) {
        console.log('Something went wrong installing the app.');
      }
    } else {
      // Is the merchant using ShopifyV2 via the legacy Custom App flow?
      if (isMerchantUsingCustomApp(shop)) {
        config = {
          apiKey: await FirestoreService.getShopifyKeyForShop(shop),
          host,
          forceRedirect: true,
        };
      } else {
        config = {
          apiKey: SWYFT_PUBLIC_APP_API_KEY,
          host,
          forceRedirect: true,
        };
      }
      app = createApp(config);
    }
  } else {
    // Is the merchant using ShopifyV2 via the legacy Custom App flow?
    if (isMerchantUsingCustomApp(shop)) {
      config = {
        apiKey: await FirestoreService.getShopifyKeyForShop(shop),
        host,
      };
    } else {
      config = {
        apiKey: SWYFT_PUBLIC_APP_API_KEY,
        host,
      };
    }

    app = createApp(config);

    initNavBar(app);
    callback();
  }
}

export function setInitCallback(cb: () => void) {
  callback = cb;
}

export async function getSessionToken() {
  if (!app) {
    throw new Error('Shopify app not initialized');
  }

  return shopifyGetSessionToken(app);
}

export function subscribe(event: string, callback: ActionCallback) {
  if (!app) {
    throw new Error('Shopify app not initialized');
  }

  return app.subscribe(event, callback);
}

// ========= Navigation =========
let createLink: AppLink.AppLink;
let labelsLink: AppLink.AppLink;
let settingsLink: AppLink.AppLink;
let navigationMenu: NavigationMenu.NavigationMenu;
function initNavBar(app: ClientApplication<any>) {
  createLink = AppLink.create(app, {
    label: 'Create',
    destination: '/',
  });

  labelsLink = AppLink.create(app, {
    label: 'Labels',
    destination: '/labels',
  });

  settingsLink = AppLink.create(app, {
    label: 'Settings',
    destination: '/settings',
  });

  navigationMenu = NavigationMenu.create(app, {
    items: [createLink, labelsLink, settingsLink],
    active: createLink,
  });
}

export function setNavCreate() {
  navigationMenu.set({ active: createLink });
}

export function setNavLabels() {
  navigationMenu.set({ active: labelsLink });
}

export function setNavSettings() {
  navigationMenu.set({ active: settingsLink });
}

export function setNavNone() {
  navigationMenu.set({ active: undefined });
}
