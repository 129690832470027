import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Col,
  DatePicker,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
  Divider,
  Table,
  Select,
  TablePaginationConfig,
} from 'antd';
import * as FirestoreService from '../../services/firestore';
import moment, { Moment } from 'moment';
import { exportLabelsCSV, exportLabelsPDF } from '../../helpers/fileExportHelper';
import {
  EXPORT_DATE_FORMAT,
  PRINT_SELECTED_LABELS_COLUMNS,
  GENERIC_ERROR_MESSAGE,
  COMPANY_NAME,
} from '../../consts';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { grey } from '@ant-design/colors';
import { ILabelV2 } from '@swyft/swyft-common';
import { RangeValue } from 'rc-picker/lib/interface';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { AuthContext } from '../AuthProvider';
import { fulfillExternalOrders } from '../../helpers/LabelHelpers';
import { OPERATIONS_EMAIL } from '../../consts';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;

interface Props {
  selectedLabels: ILabelV2[];
  isModalVisible: boolean;
  hideModal: () => void;
  merchantId: string;
  isMerchantGroup?: boolean;
}

export const ExportLabelDataModal: React.FC<Props> = ({
  selectedLabels,
  isModalVisible,
  hideModal,
  merchantId,
  isMerchantGroup = false,
}: Props) => {
  // @ts-ignore
  const { merchant } = useContext(AuthContext);

  const [isExportingSelectedLabels, setIsExportingSelectedLabels] = useState<boolean>(false);
  const [isExportingFromDateRange, setIsExportingFromDateRange] = useState<boolean>(false);
  const [isExportingAsCSV, setIsExportingAsCSV] = useState<boolean>(false);
  const [dateRangeStart, setDateRangeStart] = useState<Moment>(moment().subtract(1, 'week'));
  const [dateRangeEnd, setDateRangeEnd] = useState<Moment>(moment());
  const [labels, setLabels] = useState<ILabelV2[]>(selectedLabels);

  useEffect(() => {
    setLabels(selectedLabels);
  }, [selectedLabels]);

  /**
   * Handles bulk export of labels as a CSV from a date range.
   */
  const handleExportLabelsCSV = async () => {
    setIsExportingAsCSV(true);
    try {
      const labels = await FirestoreService.getLabelsForDateRange(
        merchantId,
        dateRangeStart,
        dateRangeEnd
      );
      const formattedStartDate = moment(dateRangeStart).format(EXPORT_DATE_FORMAT);
      const formattedEndDate = moment(dateRangeEnd).format(EXPORT_DATE_FORMAT);
      const fileName = `${COMPANY_NAME.toLowerCase()}_labels_${formattedStartDate}_${formattedEndDate}.csv`;
      await exportLabelsCSV(fileName, labels);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsExportingAsCSV(false);
  };

  /**
   * Handles bulk export of labels as PDF from a date range.
   */
  const handleExportLabelsPDF = async () => {
    setIsExportingFromDateRange(true);
    try {
      const labels = await FirestoreService.getLabelsForDateRange(
        merchantId,
        dateRangeStart,
        dateRangeEnd
      );
      if (labels.length > 0) {
        const formattedStartDate = moment(dateRangeStart).format(EXPORT_DATE_FORMAT);
        const formattedEndDate = moment(dateRangeEnd).format(EXPORT_DATE_FORMAT);
        const fileName = `${COMPANY_NAME.toLowerCase()}_labels_${formattedStartDate}_${formattedEndDate}.pdf`;

        await exportLabelsPDF(labels, fileName);

        if (!isMerchantGroup) {
          fulfillExternalOrders(labels, merchant);
        }
      } else {
        message.error('No label found within the selected date range.');
      }
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsExportingFromDateRange(false);
  };
  /**
   * When the table data is sorted, update state csvFile.data
   */
  const handleTableDataChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<ILabelV2> | SorterResult<ILabelV2>[],
    extra: { currentDataSource: ILabelV2[] }
  ): void => {
    setLabels(extra.currentDataSource as any);
  };
  /**
   * Handles exporting only the labels corresponding to the selected rows.
   */
  const handleExportSelectedLabels = async () => {
    setIsExportingSelectedLabels(true);
    if (labels.length > 0) {
      try {
        await exportLabelsPDF(labels);
        if (!isMerchantGroup) {
          fulfillExternalOrders(labels, merchant);
        }
      } catch (err) {
        message.error(GENERIC_ERROR_MESSAGE);
      }
    } else {
      message.error('No label selected.');
    }
    setIsExportingSelectedLabels(false);
  };

  const handleSetDateRange = (range: RangeValue<Moment>) => {
    try {
      if (range && range[0] && range[1]) {
        setDateRangeStart(range[0]);
        setDateRangeEnd(range[1]);
      }
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
  };

  return (
    <Modal
      title="Export Labels"
      visible={isModalVisible}
      width={'1000px'}
      onCancel={hideModal}
      footer={null}
    >
      <Row justify="center" align="middle">
        <Text>
          {selectedLabels.length
            ? `Print selected labels (${selectedLabels.length}) in the sorted order below`
            : 'No Labels selected'}
        </Text>
      </Row>
      <Row justify="center" align="middle" style={{ padding: '1em' }}>
        <Table
          dataSource={[...labels]}
          size="small"
          columns={PRINT_SELECTED_LABELS_COLUMNS}
          locale={{ emptyText: 'No Labels Selected' }}
          scroll={{ y: 200 }}
          pagination={false}
          onChange={handleTableDataChange}
        />
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ padding: '.6em' }}>
          <Button
            type="primary"
            onClick={handleExportSelectedLabels}
            loading={isExportingSelectedLabels}
          >
            Print Selected Labels {selectedLabels.length > 0 && `(${selectedLabels.length})`}
          </Button>
        </Col>
      </Row>
      <Divider>
        <h3>Or</h3>
      </Divider>
      <Row justify="center" align="middle">
        <Text> Print all labels within a given date range</Text>
      </Row>
      <Row justify="center" align="middle">
        <Col style={{ padding: '.6em 0' }}>
          <Row style={{ paddingBottom: '.2em' }} justify="start" align="middle">
            <Tooltip
              title={`Maximum date range is 31 days. For larger exports, please contact ${OPERATIONS_EMAIL}`}
            >
              <Text>
                Date Range <QuestionCircleOutlined style={{ color: grey[3] }} />
              </Text>
            </Tooltip>
          </Row>
          <Row justify="start" align="middle">
            <RangePicker
              style={{ width: '100%' }}
              value={[dateRangeStart, dateRangeEnd]}
              format={'MMM. Do, YYYY'}
              onCalendarChange={handleSetDateRange}
              disabledDate={(current) =>
                moment(dateRangeStart).isBefore(moment(current).subtract(30, 'days'))
              }
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        {!isMerchantGroup && (
          <Col style={{ padding: '.6em' }}>
            <Button type="primary" onClick={handleExportLabelsCSV} loading={isExportingAsCSV}>
              Export raw CSV
            </Button>
          </Col>
        )}
        <Col style={{ padding: '.6em' }}>
          <Button type="primary" onClick={handleExportLabelsPDF} loading={isExportingFromDateRange}>
            Print as PDF
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
