import { SMSTemplateTags, SMSType } from '@swyft/swyft-common';
import { Typography } from 'antd';
import moment from 'moment';
import { getTrackingPageUrl } from '../consts';

const FIRST_NAME = 'Jacob';
const TRACKING_URL = getTrackingPageUrl('12345abcdef');
const ESTIMATED_DELIVERY_TIME = '10 minutes';
const PAST_DELIVERY_ATTEMPT_DATE = moment().add(-1, 'd').format('MMM DD, YYYY');

export const getPreview = (template: string, merchantName: string) => {
  const templateTagPattern = /{\w+}/g;

  const content = template.replace(templateTagPattern, (tagMatch) => {
    switch (tagMatch) {
      case '{firstName}':
        return FIRST_NAME;
      case '{businessName}':
        return merchantName;
      case '{trackingUrl}':
        return TRACKING_URL;
      case '{estimatedDeliveryTime}':
        return ESTIMATED_DELIVERY_TIME;
      case '{pastDeliveryAttemptDate}':
        return PAST_DELIVERY_ATTEMPT_DATE;
      default:
        return '';
    }
  });

  return content;
};

export const TagTooltip = () => {
  return (
    <>
      <Typography>{SMSTemplateTags.firstName} - Customer first name</Typography>
      <Typography>{SMSTemplateTags.businessName} - Your business name</Typography>
      <Typography>{SMSTemplateTags.trackingUrl} - URL of the tracking page</Typography>
      <Typography>{SMSTemplateTags.estimatedDeliveryTime} - Estimated delivery time</Typography>
      <Typography>
        {SMSTemplateTags.pastDeliveryAttemptDate} - Date of the most recent delivery attempt
      </Typography>
    </>
  );
};

export const MessageTooltip = (props: { merchantName: string }) => {
  return (
    <>
      <Typography>
        In the preview, {SMSTemplateTags.firstName} will be '{FIRST_NAME}',
      </Typography>
      <Typography>
        {SMSTemplateTags.businessName} will be '{props.merchantName}',
      </Typography>
      <Typography>
        {SMSTemplateTags.trackingUrl} will be '{TRACKING_URL}',
      </Typography>
      <Typography>
        {SMSTemplateTags.estimatedDeliveryTime} will be '{ESTIMATED_DELIVERY_TIME}',
      </Typography>
      <Typography>
        {SMSTemplateTags.pastDeliveryAttemptDate} will be '{PAST_DELIVERY_ATTEMPT_DATE}'.
      </Typography>
    </>
  );
};

export const SMS_TAG_EXCLUSIONS = {
  [SMSType.SCHEDULED_FOR_DELIVERY]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
  [SMSType.ARRIVING]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
  [SMSType.DELIVERED]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
  [SMSType.FAILED]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
  [SMSType.RETURNING_TO_SENDER]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
  [SMSType.SCHEDULED_FOR_REDELIVERY]: [SMSTemplateTags.estimatedDeliveryTime],
  [SMSType.NEXT_FOR_DELIVERY]: [SMSTemplateTags.pastDeliveryAttemptDate],
  [SMSType.RECEIVED]: [
    SMSTemplateTags.estimatedDeliveryTime,
    SMSTemplateTags.pastDeliveryAttemptDate,
  ],
};
