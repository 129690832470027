import React, { useContext } from 'react';
import {
  FormOutlined,
  HomeOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { UserType } from '@swyft/swyft-common';
import _ from 'lodash';

const { Sider } = Layout;

interface Props {
  onSidebarCollapse: () => void;
  isSidebarCollapsed: boolean;
}

export const Sidebar: React.FunctionComponent<Props> = ({
  isSidebarCollapsed,
  onSidebarCollapse,
}) => {
  const { pathname } = useLocation() as any;

  //@ts-ignore
  const { user, merchant, merchantGroup } = useContext(AuthContext);

  const handleSelectedTab = () => {
    switch (pathname) {
      case '/settings':
        return ['7'];
      case '/merchant-group/overview':
        return ['3'];
      case '/labels':
        return ['2'];
      default:
        return ['1'];
    }
  };

  return (
    <Sider
      theme="light"
      width={200}
      style={{
        left: 0,
        position: 'sticky',
      }}
      collapsible
      onCollapse={onSidebarCollapse}
      collapsed={isSidebarCollapsed}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={handleSelectedTab()}
        style={{ height: '100%', borderRight: 0, display: 'flex', flexDirection: 'column' }}
      >
        {user.type == UserType.MERCHANT && (
          <>
            <Menu.Item key="1" icon={<FormOutlined />}>
              <Link to="/">Create</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<UnorderedListOutlined />}>
              <Link to="/labels">Labels</Link>
            </Menu.Item>
            {user.permissions?.includes('ADMIN') && (
              <Menu.Item key="5" icon={<TeamOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
            )}

            {!_.isNil(merchant?.reportURL) && (
              <Menu.Item key="6" icon={<PieChartOutlined />}>
                <Link to="/reports">Reports</Link>
              </Menu.Item>
            )}
            <Menu.Item key="7" icon={<SettingOutlined />} style={{ marginTop: 'auto' }}>
              <Link to="/settings">Settings</Link>
            </Menu.Item>
          </>
        )}
        {user.type == UserType.MERCHANT_GROUP && (
          <>
            <Menu.Item key="3" icon={<HomeOutlined />}>
              <Link to="/">Overview</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<UnorderedListOutlined />}>
              <Link to="/labels">Labels</Link>
            </Menu.Item>
            {user.permissions?.includes('ADMIN') && (
              <Menu.Item key="5" icon={<TeamOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
            )}
            {!_.isNil(merchantGroup?.reportURL) && (
              <Menu.Item key="6" icon={<PieChartOutlined />}>
                <Link to="/reports">Reports</Link>
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    </Sider>
  );
};
