import { getShopifyConfig, isShopifyContext } from '../../services/shopify';
import { ShopifySettings } from '../../components/ShopifySettings';
import React, { useEffect, useState, useContext } from 'react';
import { IMerchant, ISMSConfiguration } from '@swyft/swyft-common';
import { Space, message, Row, Col } from 'antd';
import { isEmpty, isUndefined } from 'lodash';
import { AuthContext } from '../../components/AuthProvider';
import { getMerchant, getPickupLocationsForMerchantId } from '../../services/firestore';
import { Loading } from '../Loading';
import { CutoffTime } from '../../components/CutoffTimeCard';
import { SmsConfiguration } from '../../components/SmsConfigurationCard';
import { Provider } from '@shopify/app-bridge-react';
import { GENERIC_ERROR_MESSAGE } from '../../consts';

interface IMerchantSettings {
  cutoffTime?: string;
  smsConfiguration: ISMSConfiguration;
}

export const Settings: React.FunctionComponent = () => {
  // @ts-ignore
  const { merchant } = useContext(AuthContext);

  const [merchantSettings, setMerchantSettings] = useState({} as IMerchantSettings);
  useEffect(() => {
    fetchMerchant(merchant.id);
  }, [merchant.id]);

  const fetchMerchant = async (merchantId: string) => {
    try {
      const merchant = (await getMerchant(merchantId)).data() as IMerchant;
      const pickupLocation = await getPickupLocationsForMerchantId(merchantId);

      if (pickupLocation.length === 1) {
        setMerchantSettings({
          cutoffTime: pickupLocation[0].cutoffTime,
          smsConfiguration: merchant.smsConfiguration,
        });
      } else {
        setMerchantSettings({
          smsConfiguration: merchant.smsConfiguration,
        });
      }
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }
  };

  return isEmpty(merchantSettings) ? (
    <Loading />
  ) : (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      {!isUndefined(merchantSettings.cutoffTime) && (
        <Row justify="center">
          <Col span={14}>
            <CutoffTime cutoffTime={merchantSettings.cutoffTime} />
          </Col>
        </Row>
      )}
      {/* Change false to true to render SmsConfiguration component */}
      {false && (
        <Row justify="center">
          <Col span={14}>
            <SmsConfiguration smsConfiguration={merchantSettings.smsConfiguration} />
          </Col>
        </Row>
      )}
      {isShopifyContext() && (
        <Row justify="center">
          <Col span={14}>
            <Provider config={getShopifyConfig()}>
              <ShopifySettings />
            </Provider>
          </Col>
        </Row>
      )}
    </Space>
  );
};
