import React, { useRef, useState } from 'react';
import { Card, Col, message, Row, Spin, TimePicker, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { FUNCTIONS } from '../services/functions';
import { DATE_FORMAT } from '@swyft/swyft-common';
import { range } from 'lodash';
import { GENERIC_ERROR_MESSAGE } from '../consts';

const { Text } = Typography;

interface Props {
  cutoffTime: string;
}

export const CutoffTime: React.FunctionComponent<Props> = (props) => {
  const [cutoffTime, setCutoffTime] = useState(props.cutoffTime);
  const [isUpdatingCutoffTime, setIsUpdatingCutoffTime] = useState(false);
  const timePickerRef = useRef<HTMLInputElement>();

  const handleCutoffTimeChange = async (_time: Moment | null, newCutoffTime: string) => {
    setIsUpdatingCutoffTime(true);
    timePickerRef.current?.blur();

    try {
      const { data } = await FUNCTIONS.updateCutoffTime({ cutoffTime: newCutoffTime });
      setCutoffTime(data.cutoffTime);
      message.success('Updated the cutoff time.');
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    } finally {
      setIsUpdatingCutoffTime(false);
    }
  };

  /** Specifies interval between 01:00 AM and 11:59 AM inclusively. */
  const getDisabledHours = () => {
    return range(13, 24);
  };

  /** Specifies interval between 12:01 PM  and 12:59 PM inclusively. */
  const getDisabledMinutes = (hour: number) => {
    if (hour === 12) {
      return range(1, 60);
    }
    return [];
  };

  return (
    <Card title="Cutoff time" style={{ backgroundColor: '#fafafa' }}>
      <Row justify="space-between">
        <Col>
          <Text strong>Daily cutoff time for the same day orders in range [12am, 12pm]</Text>
        </Col>
        <Col>
          <TimePicker
            value={moment(cutoffTime, DATE_FORMAT.CUT_OFF_TIME)}
            onChange={handleCutoffTimeChange}
            format={DATE_FORMAT.CUT_OFF_TIME}
            allowClear={false}
            showNow={false}
            suffixIcon={
              isUpdatingCutoffTime && <Spin size="small" indicator={<LoadingOutlined />} />
            }
            disabled={isUpdatingCutoffTime}
            ref={timePickerRef}
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
            hideDisabledOptions
          />
        </Col>
      </Row>
    </Card>
  );
};
