import { useEffect } from 'react';
import { RouterProps, withRouter } from 'react-router-dom';
import { Redirect } from '@shopify/app-bridge/actions';
import {
  isShopifyContext,
  setInitCallback,
  setNavCreate,
  setNavLabels,
  setNavNone,
  setNavSettings,
  subscribe,
} from '../services/shopify';

function ShopifyBridgeRouterBase(props: RouterProps) {
  const { history } = props;

  if (!isShopifyContext()) return null;

  useEffect(
    () =>
      setInitCallback(() => {
        try {
          return subscribe(Redirect.Action.APP, function (redirectData) {
            history.replace(redirectData.path);
            switch (redirectData.path) {
              case '/':
                setNavCreate();
                break;
              case '/labels':
                setNavLabels();
                break;
              case '/settings':
                setNavSettings();
                break;
              default:
                setNavNone();
            }
          });
        } catch (err) {
          console.log('Issue subscribing app', err);
        }
      }),
    [history]
  );

  return null;
}

export const ShopifyBridgeRouter = withRouter(ShopifyBridgeRouterBase);
