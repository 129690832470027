import * as FirestoreService from '../services/firestore';
import * as _ from 'lodash';
import React from 'react';
import { Tag, Tooltip, message } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  TrackingEventState,
  ILabelV2,
  ServiceType,
  CountryISO2,
  ICreateLabelCallableMerchantRequest,
  IMerchant,
  LabelCreationMethod,
  DATE_FORMAT,
} from '@swyft/swyft-common';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { ICsvTemplateRow } from '../types';
import { trackingEventStateAlias } from '../consts';
import { blue, yellow, green, red, grey, orange } from '@ant-design/colors';
import { InvalidPackageDimensionsError } from './errors';
import moment from 'moment';

interface LabelStateTagProps {
  state: TrackingEventState;
  failureReason?: string;
}

/**
 * Generates a colored tag for a label state
 * @param labelState the state of the label
 */
export const LabelStateTag: React.FC<LabelStateTagProps> = ({ state, failureReason }) => {
  const labelStateToColorMap = {
    [TrackingEventState.PENDING]: yellow.primary,
    [TrackingEventState.CONFIRMED]: blue.primary,
    [TrackingEventState.ASSIGNED]: blue.primary,
    [TrackingEventState.RECEIVED]: blue.primary,
    [TrackingEventState.IN_TRANSIT]: green.primary,
    [TrackingEventState.ARRIVING]: green.primary,
    [TrackingEventState.DELIVERED]: green.primary,
    [TrackingEventState.FAILED]: red.primary,
    [TrackingEventState.DELETED]: grey.primary,
    [TrackingEventState.SCHEDULED_FOR_REDELIVERY]: yellow.primary,
    [TrackingEventState.RETURNING_TO_SENDER]: yellow.primary,
    [TrackingEventState.RETURNED_TO_SENDER]: orange.primary,
    [TrackingEventState.NOT_RECEIVED]: grey.primary,
    [TrackingEventState.PROBLEM]: orange.primary,
    [TrackingEventState.STORAGE]: yellow.primary,
  };
  const tagColor = labelStateToColorMap[state] || grey.primary;
  const fontColor = tagColor === yellow.primary ? '#000' : '#fff'; // Yellow is low contrast with white text, so we change the text color to black
  return _.isEmpty(failureReason) ? (
    <Tag color={tagColor}>
      <span style={{ color: fontColor }}>
        {(trackingEventStateAlias[state] || state)
          .replace('RETURNED_TO_SENDER', 'RETURNED')
          .split('_')
          .join(' ')}
      </span>
    </Tag>
  ) : (
    <Tooltip placement="right" title={failureReason}>
      <Tag color={tagColor}>
        <span style={{ color: fontColor }}>
          {(trackingEventStateAlias[state] || state)
            .replace('RETURNED_TO_SENDER', 'RETURNED')
            .split('_')
            .join(' ')}
        </span>
      </Tag>
      <InfoCircleTwoTone />
    </Tooltip>
  );
};

export const createLabelReqFromCsvRow = (
  row: ICsvTemplateRow,
  shipDate: string,
  country: CountryISO2
): ICreateLabelCallableMerchantRequest => {
  const parsedPhoneNumber = row.Phone ? parsePhoneNumberFromString(row.Phone, country) : undefined;
  const phoneNumber = parsedPhoneNumber ? parsedPhoneNumber.number.toString() : '';

  const signatureRequiredCell = (row.Signature_Required ?? '').toLowerCase().trim();
  const signatureRequired = signatureRequiredCell === 'true';

  const currentDate = moment().format(DATE_FORMAT.SHIP_DATE);

  return {
    firstName: row.First_Name || '',
    lastName: row.Last_Name,
    addressLine1: row.Address_Line1 || '',
    addressLine2: row.Address_Line2 || '',
    city: row['City/Town'] || '',
    postalCode: row['Postal/Zip'] || '',
    province: row['Province/State'] || '',
    country,
    phoneNumber: phoneNumber || '',
    email: row.Email || '',
    notes: row.Notes || '',
    orderNumber: row.Order_Number || '',
    shipmentId: row.Shipment_Id || '',
    trackingNumber: row.Tracking_Number || '',
    shipDate,
    serviceType: shipDate === currentDate ? ServiceType.SAME_DAY : ServiceType.NEXT_DAY,
    dims: {
      length: row.Length_IN,
      width: row.Width_IN,
      height: row.Height_IN,
      weight: row.Package_Weight_LB,
    },
    signatureRequired,
    creationMethod: LabelCreationMethod.MERCHANT_DASHBOARD,
  };
};

/**
 * This sorting function does a comparison between two strings
 * and accounts for the case when either string is empty or undefined.
 *
 * The comparison operator > always returns false when either
 * argument is empty. This function puts empty values at the end
 * when sorting in descrending order
 *
 * @returns the sort order of the two strings
 */
export const sortStrings = (a?: string, b?: string): number => {
  if (!a) {
    return -1;
  } else if (!b) {
    return 1;
  } else {
    return a < b ? 1 : -1;
  }
};

/**
 * Input a label and return the full address of the destination.
 *  @returns the full address of the destination in a label
 */
export const getAddressString = (label: ILabelV2): string => `${
  label.destination?.address?.line1
}, ${label.destination?.address?.line2 ? label.destination?.address?.line2 : ''}
${label.destination?.address?.city}, ${label.destination?.address?.province}, ${
  label.destination?.address?.postalCode
}`;

/**
 * Fulfill external orders if autoFulfillmentEnabled is true
 * @param labels - Array of labels to be fulfiled.
 * @param merchant
 */
export const fulfillExternalOrders = async (labels: ILabelV2[], merchant: IMerchant) => {
  // Flag to control auto-fulfillment for Shopify label.
  const autoFulfillmentEnabled = merchant.shopifyConfig?.autoFulfillmentEnabled || false;

  // Fulfill external orders if shopify auto fulfillment enabled
  if (autoFulfillmentEnabled) {
    try {
      const result = await FirestoreService.fulfillExternalOrders(labels.map(({ id }) => id));

      if (result.shopifyFailureOrderNumbers.length === 0) {
        message.success(
          `Successfully fulfilled Shopify order for ${result.shopifySuccessOrderNumbers.length} label(s)`
        );
      } else {
        message.warning(
          `Successfully fulfilled Shopify order for ${result.shopifySuccessOrderNumbers.length} label(s)` +
            `And failed to fulfill Shopify order for ${result.shopifyFailureOrderNumbers.length} label(s) with label id(s) ${result.shopifyFailureOrderNumbers}`,
          10
        );
      }
    } catch (err) {
      message.error('Failed to fulfill Shopify Order, please manually fulfill it.');
    }
  }
};

/**
 * Check whether the provided dimensions of a package are supported.
 * The maximum weight is 20 lbs and the maximum volume is 2 cu ft
 * @param dims of the package
 */
export const validateDims = (reqs: ICreateLabelCallableMerchantRequest[]) => {
  // Conversion factor from cubic feet to cubic inches
  const CUBIC_FEET_TO_INCHES_CONVERSION_FACTOR = 1728;

  for (const req of reqs) {
    const dims = req.dims;

    if (dims && dims.height && dims.length && dims.weight && dims.width) {
      const packageVolume =
        (dims.length * dims.height * dims.width) / CUBIC_FEET_TO_INCHES_CONVERSION_FACTOR;

      if (packageVolume > 3.38) {
        throw new InvalidPackageDimensionsError(
          'Package volume exceeds largest supported size (3.38 cu ft)'
        );
      }

      if (dims.weight > 35) {
        throw new InvalidPackageDimensionsError(
          'Package weight exceeds largest supported size (35 lbs)'
        );
      }
    }
  }
};
