import React, { useContext, useEffect, useState } from 'react';
import { TrackingEventState, ILabelV2, IMerchant } from '@swyft/swyft-common';
import { Timestamp } from '@firebase/firestore-types';
import { Button, Empty, message, Space, Table, Tabs, Typography } from 'antd';
import moment from 'moment-timezone';
import { fulfillExternalOrders, LabelStateTag } from '../helpers/LabelHelpers';
import { AuthContext } from './AuthProvider';
import { InfoCircleTwoTone, LinkOutlined } from '@ant-design/icons';
import * as FirestoreService from '../services/firestore';
import { withSearch } from '@elastic/react-search-ui';
import { ISearchQuery, SearchQueryType } from './LabelsContainer';
import * as _ from 'lodash';
import { Loading } from '../pages/Loading';
import { LabelStateModal } from './LabelStateModal';
import { EXPORT_DATE_FORMAT, GENERIC_ERROR_MESSAGE, getTrackingPageUrl } from '../consts';
import { MerchantGroupCreateLabelModal } from './modals/MerchantGroupCreateLabelModal';
import { exportLabelsPDF } from '../helpers/fileExportHelper';
import { ExportLabelDataModal } from './modals/ExportLabelDataModal';

const { Link: LinkText, Text } = Typography;
const { TabPane } = Tabs;

interface PassedProps {
  searchQuery: ISearchQuery;
}

interface IElasticProps {
  isLoading: boolean;
  results: IElasticResult[];
  current: number;
  resultsPerPage: number;
  totalResults: number;
  setSearchTerm: (searchTerm: string) => void;
  setCurrent: (currentPage: number) => void;
  setResultsPerPage: (resultsPerPage?: number) => void;
  resultSearchTerm: string;
  [propName: string]: any;
}

interface IElasticResult {
  id: { raw: string };
}

type Props = IElasticProps & PassedProps;

const MultiLabelsTable: React.FunctionComponent<Props> = ({
  isLoading,
  results,
  current,
  resultsPerPage,
  totalResults,
  setSearchTerm,
  setCurrent,
  setResultsPerPage,
  resultSearchTerm,
  searchQuery,
}) => {
  //@ts-ignore
  const { merchantGroup } = useContext(AuthContext);

  const [merchants, setMerchants] = useState<IMerchant[]>([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [labels, setLabels] = useState<ILabelV2[]>([]);
  const [isLabelStateModalVisible, setIsLabelStateModalVisible] = useState<boolean>(false);
  const [isCreateLabelModalVisible, setIsCreateLabelModalVisible] = useState<boolean>(false);
  const [selectedMerchant, setSelectedMerchant] = useState<IMerchant>({} as IMerchant);
  const [selectedLabels, setSelectedLabels] = useState<ILabelV2[]>([]);
  const [isExportModalVisible, setIsExportModalVisible] = useState<boolean>(false);
  const [isPrintingLabels, setIsPrintingLabels] = useState<boolean>(false);

  const hideLabelStateModal = () => {
    setIsLabelStateModalVisible(false);
  };
  const showLabelStateModal = () => {
    setIsLabelStateModalVisible(true);
  };

  const hideCreateLabelModal = () => {
    handleTabChange(activeTab);
    setIsCreateLabelModalVisible(false);
  };

  const showCreateLabelModal = () => {
    setIsCreateLabelModalVisible(true);
  };

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const hideExportModal = () => {
    setIsExportModalVisible(false);
  };

  const LABEL_COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render: (createdAt: Timestamp) => (
        <p>{moment(createdAt.toDate()).format('MMM. D, [at] h:mm a')}</p>
      ),
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
    },
    {
      title: 'Ship Date',
      dataIndex: 'shipDate',
      render: (shipDate: string) => <p>{moment(shipDate).format('MMM. DD')}</p>,
    },
    {
      title: 'Customer',
      dataIndex: 'destination',
      render: (destination: any) => (
        <p>
          {destination.firstName} {destination.lastName}
        </p>
      ),
    },
    {
      title: 'Address',
      dataIndex: ['destination', 'address'],
      render: (address: any) => (
        <p>
          {address.line1}, {address.line2 ? `${address.line2}, ` : ''}
          {address.city}, {address.province} {address.postalCode}
        </p>
      ),
    },
    {
      title: 'Phone',
      dataIndex: ['destination', 'phone'],
    },
    {
      title: 'Email',
      dataIndex: ['destination', 'email'],
    },
    {
      title: 'Order #',
      dataIndex: 'orderNumber',
    },
    {
      title: (
        <Space>
          <Text>State</Text>
          <InfoCircleTwoTone onClick={showLabelStateModal} />
        </Space>
      ),
      dataIndex: 'state',
      align: 'center' as any,
      render: (state: TrackingEventState) => (
        <div style={{ textAlign: 'center' }}>
          <LabelStateTag state={state} />
        </div>
      ),
    },
    {
      title: 'Tracking Link',
      dataIndex: 'trackingNumber',
      align: 'center' as any,
      render: (trackingNumber: string) => (
        <div style={{ textAlign: 'center' }}>
          <LinkText
            href={getTrackingPageUrl(trackingNumber)} //TODO: Handle staging
            target="_blank"
          >
            <LinkOutlined />
          </LinkText>
        </div>
      ),
    },
    {
      title: 'Action',
      align: 'center' as any,
      render: (_text: string, label: ILabelV2) => (
        <>
          <Button type="link" onClick={() => handleExportLabels([label])}>
            Print
          </Button>
        </>
      ),
    },
  ];

  /**
   * Fetch each Merchant under the merchant-group
   */
  useEffect(() => {
    const getMerchants = async () => {
      const merchantIds = merchantGroup?.merchantIds;

      let merchants: IMerchant[] = [];
      for (const merchantId of merchantIds) {
        const merchant = await FirestoreService.getMerchant(merchantId);
        merchants.push(merchant.data() as IMerchant);
      }
      await getMerchantLabels(merchantIds[0]);

      setMerchants(merchants);
      setSelectedMerchant(merchants[0]);
      setActiveTab(merchants[0].id);
    };

    getMerchants();
  }, [merchantGroup]);

  /**
   * Handle the FuzzySearchBar input (clicking on an autocomplete option or full search)
   */
  useEffect(() => {
    if (!_.isEmpty(searchQuery)) {
      setActiveTab('search');
      // Edge case where alternating between exact and full search of the same keyword
      if (searchQuery.keyword === resultSearchTerm) {
        handleSearchByLabelIds(results.map((label: any) => label.id.raw));
      }

      // Full search of a keyword from FuzzySearchBar
      if (searchQuery.type === SearchQueryType.KEYWORD && searchQuery.keyword) {
        setSearchTerm(searchQuery.keyword); // No-op if search term is the same
      }

      // Autocomplete option selected from FuzzySearchBar
      if (searchQuery.type === SearchQueryType.LABEL_ID && searchQuery.labelId) {
        handleSearchByLabelIds([searchQuery.labelId]);
      }
    }
  }, [searchQuery]);

  /**
   * Handles displaying new labels when the ES results change.
   */
  useEffect(() => {
    handleSearchByLabelIds(results.map((label: any) => label.id.raw));
  }, [results]);

  /**
   * Handles the tab change onClick.
   */
  const handleTabChange = async (merchantId: string) => {
    const merchant = merchants.filter((merchant) => merchantId === merchant.id);
    setSelectedMerchant(merchant[0]);
    setActiveTab(merchantId);
    getMerchantLabels(merchantId);
  };

  /**
   * Handles the ES full-text search
   */
  const handleSearchByLabelIds = async (labelIds: string[]) => {
    const labels = await Promise.all(
      labelIds.map((id) =>
        FirestoreService.getLabelByMerchantIdsAndLabelId(merchantGroup.merchantIds, id)
      )
    );
    setLabels(labels);
  };

  /**
   * Fetches labels for a given merchant
   */
  const getMerchantLabels = async (merchantId: string) => {
    setActiveTab(merchantId);
    const res = await FirestoreService.streamAllLabels(merchantId);
    const labels = res.docs.map((doc) => doc.data() as ILabelV2);
    setLabels(labels);
  };

  const handleExportLabels = async (labels: ILabelV2[]) => {
    setIsPrintingLabels(true);
    const { shipDate } = labels[0];
    const formattedDate = moment(shipDate).format(EXPORT_DATE_FORMAT);
    const fileName = `${formattedDate}_labels.pdf`;

    await exportLabelsPDF(labels, fileName, true);
    setIsPrintingLabels(false);
  };

  const onSelectTableRow = (_selectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedLabels(selectedRows);
  };

  /**
   * Pagination configuration for the respective tables.
   */
  const paginationConfiguration =
    activeTab === 'search'
      ? {
          onChange: (page: number, pageSize?: number) => {
            setResultsPerPage(pageSize);
            setCurrent(page);
          },
          total: totalResults,
          current,
          pageSize: resultsPerPage,
        }
      : { pageSize: 25 };

  return (
    <>
      {_.isEmpty(merchants) ? (
        <Loading />
      ) : (
        <>
          <Tabs
            activeKey={activeTab}
            type="card"
            style={{ paddingTop: '25px' }}
            tabBarGutter={10}
            onChange={handleTabChange}
          >
            {merchants.map((merchant: IMerchant) => {
              return (
                <TabPane tab={merchant.name} key={merchant.id}>
                  <Space style={{ margin: '0.6em 0 0.8em 0' }}>
                    <Button type="primary" size="large" onClick={showCreateLabelModal}>
                      Create Label
                    </Button>
                    <Button type="primary" size="large" onClick={showExportModal}>
                      Print Selected Labels
                    </Button>
                  </Space>
                  <Table
                    rowKey="id"
                    columns={LABEL_COLUMNS}
                    dataSource={labels}
                    size="small"
                    pagination={paginationConfiguration}
                    rowSelection={{
                      type: 'checkbox',
                      onChange: onSelectTableRow,
                    }}
                  />
                </TabPane>
              );
            })}
            <TabPane tab="Search" key="search">
              <Table
                rowKey="id"
                columns={LABEL_COLUMNS}
                dataSource={labels}
                size="small"
                loading={isLoading || isPrintingLabels}
                pagination={paginationConfiguration}
                locale={{
                  emptyText: (
                    <Empty
                      style={{ margin: '6em', color: 'grey' }}
                      description="Search for a label"
                    ></Empty>
                  ),
                }}
              />
            </TabPane>
          </Tabs>
          <LabelStateModal hideModal={hideLabelStateModal} isVisible={isLabelStateModalVisible} />
          <MerchantGroupCreateLabelModal
            hideModal={hideCreateLabelModal}
            isVisible={isCreateLabelModalVisible}
            merchant={selectedMerchant}
          />
          <ExportLabelDataModal
            selectedLabels={selectedLabels}
            isModalVisible={isExportModalVisible}
            hideModal={hideExportModal}
            merchantId={selectedMerchant?.id}
            isMerchantGroup={true}
          />
        </>
      )}
    </>
  );
};

// withSearch is an HOC for accessing the headless core directly.
export default withSearch(
  ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  }: Props) => ({
    isLoading,
    results,
    current,
    resultsPerPage,
    totalResults,
    setSearchTerm,
    setCurrent,
    setResultsPerPage,
    resultSearchTerm,
    searchQuery,
  }),
)(MultiLabelsTable);
