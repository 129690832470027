import firebase from 'firebase';
import React from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { COMPANY_NAME } from '../consts';
import { isRebrandEnabled } from '@swyft/swyft-common/lib/consts';

interface ILocationState {
  from: string;
}

interface ILoginFormValues {
  username: string;
  password: string;
}

export const Login: React.FunctionComponent = () => {
  const state = useLocation().state as ILocationState;
  const history = useHistory();
  const isLoggedIn = Boolean(firebase.auth().currentUser);

  const signIn = (values: ILoginFormValues): void => {
    const { username, password } = values;
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then((_user) => {
        history.push(state?.from || '/');
        message.success('Welcome back!');
      })
      .catch((_error) => {
        message.error(`Invalid credentials. Please contact ${COMPANY_NAME}.`);
      });
  };

  const setPersistence = async (event: CheckboxChangeEvent) => {
    const persist = event.target.checked;
    firebase
      .auth()
      .setPersistence(
        persist ? firebase.auth.Auth.Persistence.SESSION : firebase.auth.Auth.Persistence.LOCAL
      );
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Row style={{ height: '90vh' }} justify="center" align="middle">
      <Col span={7}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3em' }}>
          {isRebrandEnabled ? (
            <img src="/hero-helo.png" width={256} />
          ) : (
            <img src="/hero.png" width={314} height={122} />
          )}
        </div>
        <Form name="basic" initialValues={{ remember: true }} onFinish={signIn}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Log in
            </Button>
          </Form.Item>
        </Form>

        <Row justify="space-between">
          <Checkbox onChange={setPersistence} defaultChecked>
            Remember Me
          </Checkbox>
          <Link to="/password-reset">Forgot Password</Link>
        </Row>
      </Col>
    </Row>
  );
};
