import React, { useEffect, useState } from 'react';
import { IMerchant, IMerchantGroup, UserType } from '@swyft/swyft-common';
import firebase from '../services/firebase';
import * as FirestoreService from '../services/firestore';

export const AuthContext = React.createContext({});

/**
 * @todo Create user account typing
 */
interface Context {
  user: any;
  merchant: any;
  merchantGroup: any;
}

export const AuthProvider = ({ children }: any) => {
  const [context, setContext] = useState({ user: {}, merchant: {} } as Context);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const uid = firebaseUser.uid;

        const fetchOrg = async () => {
          try {
            const getUserReq = await FirestoreService.getUser(uid);
            const user = getUserReq.docs.map((doc) => doc.data())[0];

            let merchant, merchantGroup;

            if (
              (user.type == UserType.MERCHANT || user.type === UserType.INTERNAL) &&
              user.merchantId != null
            ) {
              const getMerchantReq = await FirestoreService.getMerchant(user.merchantId);
              merchant = getMerchantReq.data() as IMerchant;
            }

            if (user.type == UserType.MERCHANT_GROUP) {
              const getMerchantGroupReq = await FirestoreService.getMerchantGroup(
                user.merchantGroupId
              );
              merchantGroup = getMerchantGroupReq.data() as IMerchantGroup;
            }

            setContext({ user, merchant, merchantGroup });
          } catch (err) {
            console.log('Something went wrong setting auth');
            console.log(err);
          }
        };
        fetchOrg();
      }
    });
  }, []);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
