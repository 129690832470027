import { Divider } from 'antd';
import React, { useContext, useState } from 'react';
import { AuthContext } from './AuthProvider';
import FuzzySearchBar from './FuzzySearchBar';
import { MultiExportLabelsModal } from './modals/MultiExportLabelsModal';
import MultiLabelsTable from './MultiLabelsTable';

interface Props {
  validSearchToken: Boolean;
}

export enum SearchQueryType {
  LABEL_ID = 'LABEL_ID',
  KEYWORD = 'KEYWORD',
}

export interface ISearchQuery {
  type: SearchQueryType;
  labelId?: string;
  keyword?: string;
}

export const MultiLabelsContainer: React.FunctionComponent<Props> = ({
  validSearchToken,
}: Props) => {
  //@ts-ignore
  const { user } = useContext(AuthContext);

  const [searchQuery, setSearchQuery] = useState<ISearchQuery>({} as any);

  return (
    <>
      {validSearchToken && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FuzzySearchBar setSearchQuery={setSearchQuery} />
          {user.permissions.includes('ADMIN') && (
            <>
              <Divider type="vertical" />
              <MultiExportLabelsModal />
            </>
          )}
        </div>
      )}
      <MultiLabelsTable searchQuery={searchQuery} />
    </>
  );
};
