import React, { useContext, useState } from 'react';
import {
  Button,
  Divider,
  Switch,
  Typography,
  Input,
  Dropdown,
  Menu,
  Modal,
  Space,
  Select,
  Row,
  Col,
  message,
  Popover,
  Card,
} from 'antd';
import { DownOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { ISMSConfiguration, SMSTemplateTags, SMSType } from '@swyft/swyft-common';
import NumberFormat from 'react-number-format';
import * as _ from 'lodash';
import { AuthContext } from './AuthProvider';
import { FUNCTIONS } from '../services/functions';
import {
  getPreview,
  SMS_TAG_EXCLUSIONS,
  TagTooltip,
  MessageTooltip,
} from '../helpers/SmsConfigurationHelper';
import { GENERIC_ERROR_MESSAGE } from '../consts';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

type SMSTemplateTagKeys = keyof typeof SMSTemplateTags;

interface ISelectedSms {
  type: SMSType;
  template: string;
}

interface Props {
  smsConfiguration: ISMSConfiguration;
}

export const SmsConfiguration: React.FunctionComponent<Props> = (props) => {
  // @ts-ignore
  const { merchant } = useContext(AuthContext);

  const [smsConfiguration, setSmsConfiguration] = useState(props.smsConfiguration);
  const [selectedSms, setSelectedSms] = useState<ISelectedSms>({} as ISelectedSms);
  const [isSwitchingSms, setIsSwitchingSms] = useState<boolean>(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState<boolean>(false);
  const [isTestSmsModalVisible, setIsTestSmsModalVisible] = useState<boolean>(false);
  const [testNumber, setTestNumber] = useState<string>('');
  const [isSendingTestSms, setIsSendingTestSms] = useState<boolean>(false);
  const [isRowExpanded, setIsRowExpanded] = useState<boolean>(false);

  const toggleCustomSms = async (checked: boolean) => {
    setIsSwitchingSms(true);
    try {
      const newSmsConfiguration = { ...smsConfiguration, isEnabled: checked };
      const { data } = await FUNCTIONS.updateSmsConfiguration({
        smsConfiguration: newSmsConfiguration,
      });
      setSmsConfiguration(data.smsConfiguration);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    } finally {
      setIsSwitchingSms(false);
    }
  };

  const handleSelectNotification = (smsType: SMSType) => {
    setSelectedSms({
      type: smsType,
      //@ts-ignore
      template: smsConfiguration.templates[smsType],
    });
  };

  const handleTemplateChanges = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedSms((prevSelectedSms) => ({ ...prevSelectedSms, template: event.target.value }));
  };

  const handleDiscardTemplateChanges = () => {
    setSelectedSms((prevSelectedSms) => ({
      ...prevSelectedSms,
      //@ts-ignore
      template: smsConfiguration.templates[selectedSms.type],
    }));
  };

  const handleSaveTemplateChanges = async () => {
    setIsSavingTemplate(true);
    try {
      const { type, template } = selectedSms;
      const newSmsConfiguration = {
        ...smsConfiguration,
        templates: {
          ...smsConfiguration.templates,
          [type]: template,
        },
      };
      const { data } = await FUNCTIONS.updateSmsConfiguration({
        smsConfiguration: newSmsConfiguration,
      });
      setSmsConfiguration(data.smsConfiguration);
      message.success(`Updated ${type} custom message.`);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    } finally {
      setIsSavingTemplate(false);
    }
  };

  const handleOpenTestSmsModal = () => {
    setIsTestSmsModalVisible(true);
  };

  const handleCloseTestSmsModal = () => {
    setIsTestSmsModalVisible(false);
  };

  const handleChangeTestNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestNumber(event.target.value);
  };

  const handleSendTestSms = async () => {
    if (testNumber.includes('_')) {
      message.error('Please enter a valid phone number!');
      return;
    }

    setIsSendingTestSms(true);

    try {
      const { template } = selectedSms;
      const content = template;
      await FUNCTIONS.testSmsNotification({
        content,
        phone: testNumber,
      });
      message.success('Test notification was sent.');
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    } finally {
      setIsSendingTestSms(false);
    }
  };

  const renderSmsOptions = Object.values(SMSType).map((value) => {
    return <Option value={value}>{_.capitalize(value.toLowerCase().replaceAll('_', ' '))}</Option>;
  });

  const TagOptions = () => {
    return (
      <Menu
        onClick={({ key }) => {
          setSelectedSms({
            ...selectedSms,
            template: `${selectedSms.template} ${SMSTemplateTags[key as SMSTemplateTagKeys]}`,
          });
        }}
      >
        {Object.entries(SMSTemplateTags)
          .filter(([_key, value]) => {
            const smsType = selectedSms.type;
            //@ts-ignore
            return !SMS_TAG_EXCLUSIONS[smsType].includes(value);
          })
          .map(([key, value]) => {
            return <Menu.Item key={key}>{value}</Menu.Item>;
          })}
      </Menu>
    );
  };

  const toggleRowExpansion = () => {
    setIsRowExpanded((prevIsRowExpanded) => !prevIsRowExpanded);
  };

  return (
    <Card title="Sms Configuration" style={{ backgroundColor: '#fafafa' }}>
      <Row justify="space-between">
        <Col>
          <Space direction="vertical">
            <Text strong>Enable custom text notifications</Text>
            <Text strong type="secondary">
              {smsConfiguration.isEnabled ? 'Enabled' : 'Disabled'}
            </Text>
          </Space>
        </Col>
        <Col>
          <Switch
            checked={smsConfiguration.isEnabled as boolean}
            onChange={toggleCustomSms}
            loading={isSwitchingSms}
          />
        </Col>
      </Row>
      {smsConfiguration.isEnabled && (
        <>
          <Divider />
          <Row
            justify="space-between"
            className="configuration-row expandable"
            onClick={toggleRowExpansion}
            style={{ cursor: 'pointer' }}
          >
            <Col>
              <Text strong>Customize text notifications</Text>
            </Col>
            <Col>{isRowExpanded ? <DownOutlined /> : <RightOutlined />}</Col>
          </Row>
          {isRowExpanded && (
            <>
              <Row justify="space-between" style={{ marginTop: '10px' }}>
                <Col>
                  <Text strong type="secondary">
                    Notification type
                  </Text>
                </Col>
                <Col>
                  <Select
                    placeholder="Select a notification type"
                    style={{ width: 210 }}
                    onChange={handleSelectNotification}
                  >
                    {renderSmsOptions}
                  </Select>
                </Col>
              </Row>
              {!_.isEmpty(selectedSms) && (
                <>
                  <Divider dashed />
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: '100%', padding: '0px 20px' }}
                  >
                    <Row>
                      <Col flex="100px">
                        <Text strong>Message</Text>
                        <Popover
                          content={<MessageTooltip merchantName={merchant.name} />}
                          trigger="click"
                        >
                          <InfoCircleOutlined style={{ marginLeft: 10 }} />
                        </Popover>
                      </Col>
                      <Col flex="auto">
                        <Dropdown
                          arrow
                          placement="bottomCenter"
                          overlay={<TagOptions />}
                          trigger={['click']}
                        >
                          <Popover content={<TagTooltip />}>
                            <Button type="dashed" style={{ width: '100%', marginBottom: 5 }}>
                              Select a tag
                            </Button>
                          </Popover>
                        </Dropdown>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <TextArea
                            allowClear
                            rows={8}
                            value={selectedSms.template}
                            onChange={handleTemplateChanges}
                          />
                          <Text type="secondary">
                            You can edit the message above and add as many tags as you like.
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                    <Row>
                      <Col flex="100px">
                        <Text strong>Preview</Text>
                      </Col>
                      <Col flex="auto">
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <TextArea
                            allowClear
                            rows={8}
                            value={getPreview(selectedSms.template, merchant.name)}
                            readOnly
                          />
                          <Text type="secondary" style={{ marginTop: 5, width: 'fit-content' }}>
                            Tags in the message are replaced with examples in the preview.
                          </Text>
                        </Space>
                      </Col>
                    </Row>
                    <Row justify="end">
                      <Button size="small" onClick={handleOpenTestSmsModal}>
                        Test notification
                      </Button>
                    </Row>
                    <Row justify="end" gutter={[15, 0]}>
                      <Col>
                        <Button
                          onClick={handleDiscardTemplateChanges}
                          disabled={
                            //@ts-ignore
                            smsConfiguration.templates[selectedSms.type] === selectedSms.template
                          }
                        >
                          Discard
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={handleSaveTemplateChanges}
                          loading={isSavingTemplate}
                          disabled={
                            //@ts-ignore
                            smsConfiguration.templates[selectedSms.type] === selectedSms.template
                          }
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Space>
                </>
              )}
            </>
          )}
        </>
      )}
      <Modal
        title="Test notification"
        centered
        visible={isTestSmsModalVisible}
        onOk={handleSendTestSms}
        okButtonProps={{ loading: isSendingTestSms }}
        onCancel={handleCloseTestSmsModal}
        okText="Send"
        width={300}
      >
        <NumberFormat
          placeholder="Enter phone number"
          value={testNumber}
          onChange={handleChangeTestNumber}
          customInput={Input}
          format="+1 (###) ###-####"
          mask="_"
        />
      </Modal>
    </Card>
  );
};
