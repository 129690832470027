import React from 'react';
import { Tabs } from 'antd';
import { CreateLabelForm } from '../../components/CreateLabelForm';
import { ImportCsv } from '../../components/ImportCsv';
import { useContext } from 'react';
import { AuthContext } from '../../components/AuthProvider';

const { TabPane } = Tabs;

export const Create: React.FC = () => {
  // @ts-ignore
  const { merchant } = useContext(AuthContext);

  return (
    <Tabs defaultActiveKey="1" size="large">
      <TabPane tab="Create a label" key="1">
        <CreateLabelForm merchant={merchant} isMerchantGroup={false}/>
      </TabPane>
      <TabPane tab="Upload a CSV" key="2">
        <ImportCsv merchant={merchant} isMerchantGroup={false}/>
      </TabPane>
    </Tabs>
  );
};
