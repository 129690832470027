import { Modal, Space, Tabs } from 'antd';
import { CreateLabelForm } from '../CreateLabelForm';
import { ImportCsv } from '../ImportCsv';
import { IMerchant } from '@swyft/swyft-common';

const { TabPane } = Tabs;

interface Props {
  hideModal: () => void;
  isVisible: boolean;
  merchant: IMerchant;
}

export const MerchantGroupCreateLabelModal = ({ hideModal, isVisible, merchant }: Props) => {
  return (
    <>
      <Modal
        title="Create Label"
        visible={isVisible}
        onCancel={hideModal}
        footer={null}
        width={1200}
        bodyStyle={{ height: 900 }}
      >
        <Space direction="vertical" style={{ width: '100%', padding: '0 50px 0 50px' }}>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="Create a label" key="1">
              <CreateLabelForm merchant={merchant} isMerchantGroup={true} />
            </TabPane>
            <TabPane tab="Upload a CSV" key="2">
              <ImportCsv merchant={merchant} isMerchantGroup={true} />
            </TabPane>
          </Tabs>
        </Space>
      </Modal>
    </>
  );
};
