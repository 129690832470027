import { DocumentSnapshot } from '@firebase/firestore-types';
import moment, { Moment } from 'moment';
import firebase from './firebase';
import {
  ICreateUserCallableRequest,
  ILabelV2,
  IUser,
  TrackingEventState,
  IDeleteUserRequest,
  IPickupLocation,
  IFulfillExternalOrdersResponse,
  IExcludedItem,
  IDeliveryAttempt,
  IShopifyDiscountSettings,
  ICreateLabelCallableDashboardRequest,
} from '@swyft/swyft-common';

// 540 seconds is the max function timeout setting this to 5 second more
const CLOUD_FUNCTION_MAX_TIMEOUT = 545000;

export const db = firebase.firestore();

const getLabelsV2Collection = (merchantId: string) => {
  return db.collection('merchants').doc(merchantId).collection('labelsV2');
};

export const getUser = (uid: string) => {
  return db.collection('users').where('id', '==', uid).limit(1).get();
};

export const getMerchant = (merchantId: string) => {
  return db.collection('merchants').doc(merchantId).get();
};

export const getMerchantGroup = (merchantGroupId: string) => {
  return db.collection('merchant-groups').doc(merchantGroupId).get();
};

export const streamAllLabels = (merchantId: string) => {
  return getLabelsV2Collection(merchantId).orderBy('createdAt', 'desc').limit(100).get();
};

export const getLabelsForDateRange = async (
  merchantId: string,
  dateRangeStart: Moment,
  dateRangeEnd: Moment
) => {
  const result = await getLabelsV2Collection(merchantId)
    .where('createdAt', '>=', dateRangeStart.startOf('day').toDate())
    .where('createdAt', '<=', dateRangeEnd.endOf('day').toDate())
    .orderBy('createdAt', 'desc')
    .get();

  const labels = result.docs
    .map((docSnapshot: DocumentSnapshot) => docSnapshot.data())
    .filter((label) => label?.state !== TrackingEventState.DELETED);

  return labels as ILabelV2[];
};

export const getDailyLabels = (merchantId: string) => {
  return getLabelsV2Collection(merchantId)
    .where('createdAt', '<=', moment().toDate())
    .where('createdAt', '>=', moment().subtract('1', 'week').toDate())
    .orderBy('createdAt', 'desc')
    .get();
};

export const deleteLabel = async (labelId: string) => {
  try {
    const deleteLabel = firebase.functions().httpsCallable('httpsOnCallDeleteLabelWithLabelId');
    await deleteLabel({
      labelId,
    });
  } catch (error) {
    return error;
  }
};

export const createUser = async (req: ICreateUserCallableRequest) => {
  try {
    const createUser = firebase.functions().httpsCallable('httpsOnCallUser-createUser');
    await createUser(req);
  } catch (error) {
    return error;
  }
};

/**
 * Creates a label
 * @param createLabelRequest
 * @returns the created label id
 */
export const createLabel = async (createLabelRequest: any): Promise<string> => {
  const createLabel = firebase.functions().httpsCallable('httpsOnCallLabels-createLabel');
  const {
    data: { merchantLabelId },
  } = await createLabel(createLabelRequest);
  return merchantLabelId;
};

/**
 * Creates a label for merchant group
 * @param createLabelRequest
 * @returns the created label id
 */
export const merchantGroupCreateLabel = async (createLabelRequest: ICreateLabelCallableDashboardRequest): Promise<string> => {
  const createLabel = firebase.functions().httpsCallable('httpsOnCallLabels-merchantGroupCreateLabel');
  const {
    data: { merchantLabelId },
  } = await createLabel(createLabelRequest);
  return merchantLabelId;
};

/**
 * Creates labels in batch
 * @param createLabelRequests
 * @returns arrays of merchant and org label ids or an error
 */
export const batchCreateLabels = async (createLabelRequests: any[]) => {
  const batchCreateLabels = firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-batchCreateLabelsFromCsv', {
      timeout: CLOUD_FUNCTION_MAX_TIMEOUT,
    });
  const { data } = await batchCreateLabels(createLabelRequests);
  return data;
};

/**
 * Creates labels in batch for merchant group
 * @param createLabelRequests
 * @returns arrays of merchant and org label ids or an error
 */
export const merchantGroupBatchCreateLabels = async (createLabelRequests: ICreateLabelCallableDashboardRequest[]) => {
  const merchantGroupBatchCreateLabels = firebase
    .functions()
    .httpsCallable('httpsOnCallLabels-merchantGroupBatchCreateLabelsFromCsv', {
      timeout: CLOUD_FUNCTION_MAX_TIMEOUT,
    });
  const { data } = await merchantGroupBatchCreateLabels(createLabelRequests);
  return data;
};

/**
 * Gets a label by merchant and label ID
 * @param labelId
 * @param merchantId
 * @returns the label
 */
export const getLabelByMerchantIdAndLabelId = async (
  merchantId: string,
  labelId: string
): Promise<ILabelV2> => {
  const query = await db
    .collectionGroup('labelsV2')
    .where('merchantId', '==', merchantId)
    .where('id', '==', labelId)
    .get();
  return query.docs[0].data() as ILabelV2;
};

/**
 * Gets a label by multiple merchant IDs and label ID
 * @param labelId
 * @param merchantIds array of merchant IDs to filter on
 * @returns the label
 */
export const getLabelByMerchantIdsAndLabelId = async (
  merchantIds: string[],
  labelId: string
): Promise<ILabelV2> => {
  const query = await db
    .collectionGroup('labelsV2')
    .where('merchantId', 'in', merchantIds)
    .where('id', '==', labelId)
    .get();
  return query.docs[0].data() as ILabelV2;
};

/**
 * Fetch the users associated with a merchant
 * @param merchantId
 * @returns the users
 */
export const getUsersForMerchantId = async (merchantId: string): Promise<IUser[]> => {
  const result = await db
    .collection('users')
    .where('merchantId', '==', merchantId)
    .where('type', '==', 'MERCHANT')
    .get();

  const users = result.docs.map((docSnapshot: DocumentSnapshot) => docSnapshot.data());

  return users as IUser[];
};

/**
 * Fetch the users associated with a merchant-group
 * @param merchantGroupId
 * @returns the users
 */
export const getUsersForMerchantGroupId = async (merchantGroupId: string): Promise<IUser[]> => {
  const result = await db
    .collection('users')
    .where('merchantGroupId', '==', merchantGroupId)
    .where('type', '==', 'MERCHANT_GROUP')
    .get();

  const users = result.docs.map((docSnapshot: DocumentSnapshot) => docSnapshot.data());
  return users as IUser[];
};

/**
 * Deletes a user
 * @param userEmail
 * @returns the users
 */
export const deleteUser = async (req: IDeleteUserRequest) => {
  try {
    const deleteUser = firebase.functions().httpsCallable('httpsOnCallUser-deleteUser');
    await deleteUser(req);
  } catch (error) {
    return error;
  }
};

/**
 * Converts a shopify session token to a firestore session
 * @param sessionToken
 * @returns firebaseToken
 */
export const shopifySessionLogin = async (sessionToken: string) => {
  const shopifySessionLoginCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallShopifySessionLogin');

  const {
    data: { firebaseToken },
  } = await shopifySessionLoginCallable({ sessionToken });
  return firebaseToken;
};

/**
 * Stores checkout config settings for a shopify merchant
 * @param checkoutEnabled
 */
export const updateShopifyCheckoutConfig = async (checkoutEnabled: boolean) => {
  const shopifyCheckoutConfigCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallUpdateShopifyCheckoutConfig');

  return shopifyCheckoutConfigCallable({ checkoutEnabled });
};

/**
 * Stores discount config settings for a shopify merchant
 * @param merchantId
 * @param discountSettings
 */
export const updateShopifyDiscountConfig = async (discountSettings: IShopifyDiscountSettings) => {
  const shopifyCheckoutConfigCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallUpdateShopifyDiscountConfig');

  return shopifyCheckoutConfigCallable(discountSettings);
};

/**
 * Stores excludedItemsEnabled flag for a shopify merchant
 * @param excludedItemsEnabled
 */
export const updateShopifyExcludedItemsConfig = async (excludedItemsEnabled: boolean) => {
  const shopifyExcludedItemsConfigCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallUpdateShopifyExcludedItemsConfig');

  return shopifyExcludedItemsConfigCallable({
    excludedItemsEnabled,
  });
};

/**
 * Updates the shipping price for a shopify merchant
 * @param shippingPriceCents
 */
export const updateShopifyShippingPrice = async (shippingPriceCents: number) => {
  const shopifyExcludedItemsConfigCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallUpdateShopifyShippingPrice');

  return shopifyExcludedItemsConfigCallable({
    shippingPriceCents,
  });
};

/**
 * Fetches the merchant with the corresponding shopOrigin
 * @param shopOrigin
 * @returns merchant if exists otherwise null
 */
export const getMerchantByShopOrigin = async (shopOrigin: string): Promise<any> => {
  const res = await db
    .collection('merchants')
    .where('shopifyConfig.shopName', '==', shopOrigin)
    .get();

  if (res.docs.length > 1) {
    console.log('Something went wrong fetching merchant for shopOrigin');
  } else if (res.docs.length === 0) {
    return null;
  } else {
    return res.docs[0].data();
  }
};

/**
 * Fetches a shopify API Key for a given shop URL
 * @param shop
 */
export const getShopifyKeyForShop = async (shop: string): Promise<string> => {
  const shopifyKeyCallable = firebase.functions().httpsCallable('httpsOnCallGetShopifyKeyForShop');

  const {
    data: { key },
  } = ((await shopifyKeyCallable({ shop })) as unknown) as { data: { key: string } };
  return key;
};

/**
 * Determines if a Shopify Merchant has been onboarded
 * @param shop
 */
export const isShopifyMerchantOnboarded = async (shop: string): Promise<boolean> => {
  const shopifyKeyCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallIsShopifyMerchantOnboarded');

  const { data } = ((await shopifyKeyCallable({ shop })) as unknown) as { data: boolean };
  return data;
};

/**
 * Fetch the pickup locations associated with a merchant
 * @param merchantId
 * @returns array of the pickup locations
 */
export const getPickupLocationsForMerchantId = async (
  merchantId: string
): Promise<IPickupLocation[]> => {
  const result = await db
    .collection('merchants')
    .doc(merchantId)
    .collection('pickup-locations')
    .get();

  const pickupLocations = result.docs.map((docSnapshot: DocumentSnapshot) => docSnapshot.data());
  return pickupLocations as IPickupLocation[];
};

/**
 * Updates the list of excluded-items for a given merchant
 * @param merchantId
 * @param excludedItemIds
 */
export const updateExcludedItems = async (
  merchantId: string,
  excludedItemIds: string[]
): Promise<string[]> => {
  const batchUpdateExcludedItemsCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallUpdateExcludedItems');

  const { data } = await batchUpdateExcludedItemsCallable({ merchantId, excludedItemIds });
  return data;
};

/**
 * Fetch the excluded items associated with a merchant
 * @param merchantId
 * @returns array of the pickup locations
 */
export const getExcludedItemsForMerchantId = async (
  merchantId: string
): Promise<IExcludedItem[]> => {
  const result = await db
    .collection('merchants')
    .doc(merchantId)
    .collection('excluded-items')
    .get();

  const excluldedItems = result.docs.map((docSnapshot: DocumentSnapshot) => docSnapshot.data());
  return excluldedItems as IExcludedItem[];
};

/**
 * Fulfill external orders for given labels
 * @param labelIds
 */
export const fulfillExternalOrders = async (
  labelIds: string[]
): Promise<IFulfillExternalOrdersResponse> => {
  const fulfillExternalOrdersCallable = firebase
    .functions()
    .httpsCallable('httpsOnCallFulfillExternalOrders');

  const { data } = await fulfillExternalOrdersCallable({ labelIds });

  return data;
};

/**
 * Fetches the latest delivery attempt for the given label
 * @param labelId  id of label to fetch delivery attempt for
 * @param merchantId id of merchant
 * @returns the latest delivery attempt from db
 */
export const getLatestDeliveryAttemptForLabel = async (
  labelId: string,
  merchantId: string
): Promise<IDeliveryAttempt> => {
  const result = await getLabelsV2Collection(merchantId)
    .doc(labelId)
    .collection('delivery-attempts')
    .orderBy('completionTime', 'desc')
    .limit(1)
    .get();

  return result.docs[0]?.data() as IDeliveryAttempt;
};

/**
 * Returns a PDF label
 * @param printPdfLabelsRequest 
 * @returns PDF label
 */
 export const printPdfLabels = async (
  printPdfLabelsRequest: any,
): Promise<string> => {

  const printPdfLabelsCallable = firebase
    .functions()
    .httpsCallable('labels-printPdfLabels');
  
  const { data } = await printPdfLabelsCallable(printPdfLabelsRequest);
  return data;
};
