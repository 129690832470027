import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import { PrivateRoute } from './components/PrivateRoute';
import { ShopifyBridgeRouter } from './components/ShopifyBridgeRouter';
import { Home } from './pages/Home';
import { Loading } from './pages/Loading';
import { Login } from './pages/Login';
import { PasswordReset } from './pages/passwordReset';
import firebase from './services/firebase';
import { getSessionToken, isShopifyContext, shopifyInit } from './services/shopify';
import { shopifySessionLogin } from './services/firestore';
import { Button, Result } from 'antd';
import { isRebrandEnabled } from '@swyft/swyft-common/lib/consts';
import { COMPANY_NAME } from './consts';

function App() {
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [isShowSalesForm, setIsShowSalesForm] = useState(false);

  /**
   * Ensures that Firebase finishes Auth initialization to determine the state of the user.
   * https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
   *
   * By waiting here, we can use firebase.auth().currentUser downstream to detect auth status.
   * */
  firebase.auth().onAuthStateChanged((firebaseUser) => {
    setUserExists(!!firebaseUser);
    setIsFirebaseInitialized(true);
  });

  useEffect(() => {
    const effect = async () => {
      if (!isShopifyContext()) return;
      await shopifyInit();

      const sessionToken = await getSessionToken();

      /**
       * If shopifySessionLogin failed, it means failed to fetch merchant with given session token
       * then displaying sales form modal
       */
      try {
        const firebaseToken = await shopifySessionLogin(sessionToken);
        await firebase.auth().signInWithCustomToken(firebaseToken);
      } catch (err) {
        // Merchant account does not exists
        setIsShowSalesForm(true);
      }
    };

    effect().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (isRebrandEnabled) {
      try {
        // TODO: edit public/index.html with these value when rebrand has happened
        document.title = 'Helo Merchant'; // Changes document title
        // Change favicon
        const favicon = document.querySelector("link[rel*='icon']");
        if (favicon) {
          // @ts-ignore
          favicon.href = '/favicon-helo.png';
        }
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          // Change description
          metaDescription.setAttribute('content', 'Helo Merchant Dashboard');
        }
      } catch (err) {
        console.error('Error updating to Helo rebrand', err);
      }
    }
  }, [isRebrandEnabled]);

  return isFirebaseInitialized ? (
    isShopifyContext() && !userExists ? (
      isShowSalesForm ? (
        <Result
          title={`Thank you for installing the ${COMPANY_NAME} app, please fill out our merchant onboarding form and \
          our customer success team will reach out to you soon. \
          Please contact 'success@useswyft.com' for more information.`}
          extra={
            <Button type="primary">
              <a href="https://share.hsforms.com/1aAZUji16Qt21vDc9lVZvYw3zvhf" target="_blank">
                Go to the Form
              </a>
            </Button>
          }
        />
      ) : (
        <Loading />
      )
    ) : (
      <AuthProvider>
        <Router>
          <ShopifyBridgeRouter />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/password-reset" component={PasswordReset} />
            <PrivateRoute path="/">
              <Home />
            </PrivateRoute>
          </Switch>
        </Router>
      </AuthProvider>
    )
  ) : (
    <Loading />
  );
}

export default App;
