import { useContext, useState } from 'react';
import { ICreateUserCallableRequest, UserType, UserPermissions } from '@swyft/swyft-common';
import { Button, Divider, Form, Input, Modal, Checkbox, message } from 'antd';
import * as FirestoreService from '../../services/firestore';
import { AuthContext } from '../AuthProvider';
import { GENERIC_ERROR_MESSAGE } from '../../consts';

interface Props {
  onFinish: () => void;
}

export const AddUserButtonModal = ({ onFinish }: Props) => {
  //@ts-ignore
  const { user, merchant, merchantGroup } = useContext(AuthContext);

  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await form.validateFields(); // Interrupts code execution

      setIsLoading(true);
      let createUserRequest = {} as ICreateUserCallableRequest;
      switch (user.type) {
        case UserType.MERCHANT:
          createUserRequest = {
            firstName: form.getFieldValue('firstName'),
            lastName: form.getFieldValue('lastName'),
            email: form.getFieldValue('email'),
            password: form.getFieldValue('password'),
            permissions: form.getFieldValue('admin') ? [UserPermissions.ADMIN] : [],
            type: UserType.MERCHANT,
            merchantId: merchant.id,
          };
          break;
        case UserType.MERCHANT_GROUP:
          createUserRequest = {
            firstName: form.getFieldValue('firstName'),
            lastName: form.getFieldValue('lastName'),
            email: form.getFieldValue('email'),
            password: form.getFieldValue('password'),
            permissions: form.getFieldValue('admin') ? [UserPermissions.ADMIN] : [],
            type: UserType.MERCHANT_GROUP,
            merchantGroupId: merchantGroup.id,
          };
          break;

        default:
          message.error(GENERIC_ERROR_MESSAGE);
          console.log('Error. Unable to determine user type.');
      }

      await FirestoreService.createUser(createUserRequest);
      onFinish();
      setIsLoading(false);
      message.success(`User created: ${createUserRequest.email}`);
      form.resetFields();
      setIsModalVisible(false);
    } catch {}
  };

  const handleCancel = () => {
    form.resetFields();
    setIsLoading(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add User
      </Button>
      <Modal
        title="Create user"
        visible={isModalVisible}
        okText="Create"
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          {...layout}
        >
          <Form.Item
            label="First Name:"
            name="firstName"
            rules={[{ required: true, message: 'Please enter a first name.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name:"
            name="lastName"
            rules={[{ required: true, message: 'Please enter a last name.' }]}
          >
            <Input />
          </Form.Item>
          <Divider />
          <Form.Item
            label="Email:"
            name="email"
            rules={[{ required: true, message: 'Please enter an email.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password:"
            name="password"
            rules={[{ required: true, message: 'Password must be at least 8 characters.', min: 8 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Admin:"
            name="admin"
            valuePropName="checked"
            rules={[{ required: false, message: 'User is admin?' }]}
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
