import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Select, DatePicker, Space, message } from 'antd';
import { AuthContext } from '../AuthProvider';
import { IMerchant } from '@swyft/swyft-common';
import * as FirestoreService from '../../services/firestore';
import moment, { Moment } from 'moment';
import { EXPORT_DATE_FORMAT, GENERIC_ERROR_MESSAGE } from '../../consts';
import { exportLabelsCSV } from '../../helpers/fileExportHelper';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const MultiExportLabelsModal: React.FC = () => {
  //@ts-ignore
  const { merchantGroup } = useContext(AuthContext);

  const [isModalVisible, setIsModalVisible] = useState<boolean | undefined>(false);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const [merchants, setMerchants] = useState<IMerchant[]>([]);
  const [selectedMerchantId, setSelectedMerchantId] = useState<string>('');
  const [dateRangeStart, setDateRangeStart] = useState<Moment>(moment().subtract(1, 'week'));
  const [dateRangeEnd, setDateRangeEnd] = useState<Moment>(moment());

  /**
   * Fetch each Merchant under the merchant-group
   */
  useEffect(() => {
    const getMerchants = async () => {
      const merchantIds = merchantGroup?.merchantIds;

      let merchants: IMerchant[] = [];
      for (const merchantId of merchantIds) {
        const merchant = await FirestoreService.getMerchant(merchantId);
        merchants.push(merchant.data() as IMerchant);
      }
      setMerchants(merchants);
    };

    getMerchants();
  }, [merchantGroup]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMerchantIdSelection = (merchantId: string) => {
    setSelectedMerchantId(merchantId);
  };

  const handleSetDateRange = (range: any) => {
    if (range[0] && range[1]) {
      setDateRangeStart(range[0]);
      setDateRangeEnd(range[1]);
    }
  };

  const handleExportLabelsCSV = async () => {
    setIsLoading(true);
    try {
      const labels = await FirestoreService.getLabelsForDateRange(
        selectedMerchantId,
        dateRangeStart,
        dateRangeEnd
      );
      const formattedStartDate = moment(dateRangeStart).format(EXPORT_DATE_FORMAT);
      const formattedEndDate = moment(dateRangeEnd).format(EXPORT_DATE_FORMAT);
      const fileName = `export_${formattedStartDate}_${formattedEndDate}.csv`;
      await exportLabelsCSV(fileName, labels);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
    setIsLoading(false);
  };

  const handleOk = async () => {
    try {
      setIsLoading(true);

      handleExportLabelsCSV();

      setIsLoading(false);
      setIsModalVisible(false);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
    }
  };

  return (
    <>
      <Button type="primary" size="large" onClick={showModal}>
        Export
      </Button>
      <Modal
        title="Export"
        visible={isModalVisible}
        okText="Export"
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <Space direction="vertical" style={{ width: '100%', padding: '0 50px 0 50px' }}>
          <Select
            placeholder="Select a store"
            style={{ width: '100%' }}
            onChange={handleMerchantIdSelection}
          >
            {merchants.map((merchant) => {
              return (
                <Option value={merchant.id} key={merchant.id}>
                  {merchant.name}
                </Option>
              );
            })}
          </Select>
          <RangePicker
            style={{ width: '100%' }}
            value={[dateRangeStart, dateRangeEnd]}
            format={'MMM. Do, YYYY'}
            onCalendarChange={handleSetDateRange}
            disabledDate={(current) =>
              moment(dateRangeStart).isBefore(moment(current).subtract(30, 'days'))
            }
          />
        </Space>
      </Modal>
    </>
  );
};
