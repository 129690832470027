import { useEffect, useState } from 'react';
import * as FirestoreService from '../services/firestore';
import moment from 'moment';
import {
  DATE_FORMAT,
  CountryISO2,
  ServiceType,
  IPickupLocation,
  ICreateLabelCallableDashboardRequest,
  LabelCreationMethod,
  ICreateLabelCallableMerchantRequest,
} from '@swyft/swyft-common';
import { getEarliestShipDate } from '@swyft/swyft-common/lib/helpers/ShipDateHelpers';
import { Alert, Button, Col, Form, Input, message, Row, Space, Modal, Typography } from 'antd';
import { COMPANY_NAME } from '../consts';
import _ from 'lodash';
import { validateDims } from '../helpers/LabelHelpers';
import { exportLabelsPDF } from '../helpers/fileExportHelper';
import { EXPORT_DATE_FORMAT, GENERIC_ERROR_MESSAGE } from '../consts';
import { merchantProps } from '../types';

const { Text } = Typography;

interface ICreateLabelFormValues {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  province: string;
  country: CountryISO2;
  phoneNumber: string;
  email: string;
  shipDate: string;
  orderNumber?: string;
  shipmentId?: string;
  notes?: string;
  length: number;
  width: number;
  height: number;
  weight: number;
  signatureRequired: boolean;
  merchantName: string;
}

export const CreateLabelForm = ({ merchant, isMerchantGroup }: merchantProps) => {
  const country = merchant?.contact?.address?.country?.toUpperCase() as CountryISO2;

  const [form] = Form.useForm<ICreateLabelFormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickupLocation, setPickupLocation] = useState<IPickupLocation>({} as IPickupLocation);
  const [shipDate, setShipDate] = useState<string>('');

  useEffect(() => {
    if (!_.isEmpty(merchant)) {
      fetchPickupLocation(merchant.id);
    }
  }, [merchant]);

  useEffect(() => {
    if (!_.isEmpty(merchant)) {
      setFormDefaults();
    }
  }, [merchant, pickupLocation]);

  const fetchPickupLocation = async (merchantId: string) => {
    try {
      const pickupLocationData = await FirestoreService.getPickupLocationsForMerchantId(merchantId);
      setPickupLocation(pickupLocationData[0]);
    } catch (err) {
      message.error(GENERIC_ERROR_MESSAGE);
      console.error(err);
    }
  };

  const setFormDefaults = () => {
    const {
      contact: {
        address: { province },
      },
    } = merchant;

    const { cutoffTime } = pickupLocation;

    if (!_.isUndefined(cutoffTime)) {
      const shipDate = getEarliestShipDate(pickupLocation);

      form.setFieldsValue({
        province,
        country,
        shipDate: shipDate.format('MMM DD, YYYY'),
        merchantName: merchant.name,
      });
    }
  };

  const resetForm = () => {
    form.resetFields();
    setFormDefaults();
  };

  const createLabel = async (values: ICreateLabelFormValues) => {
    setIsLoading(true);

    const currentShipDate = moment(values.shipDate, 'MMM DD, YYYY').format(DATE_FORMAT.SHIP_DATE); // Convert the human readable date back to the required format
    const earliestShipDate = getEarliestShipDate(pickupLocation);

    setShipDate(earliestShipDate.format(DATE_FORMAT.READABLE_SHIP_DATE));

    const formattedEarliestShipDate = earliestShipDate.format(DATE_FORMAT.SHIP_DATE);

    const currentDate = moment().format(DATE_FORMAT.SHIP_DATE);

    if (currentShipDate !== formattedEarliestShipDate) {
      setIsModalVisible(true);
    } else {
      const createLabelRequest: ICreateLabelCallableMerchantRequest = {
        firstName: values.firstName,
        lastName: values.lastName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        postalCode: values.postalCode,
        province: values.province,
        country: values.country,
        phoneNumber: values.phoneNumber,
        email: values.email,
        orderNumber: values.orderNumber,
        shipmentId: values.shipmentId,
        notes: values.notes,
        shipDate: currentShipDate,
        serviceType: currentShipDate === currentDate ? ServiceType.SAME_DAY : ServiceType.NEXT_DAY,
        dims: {
          length: values.length,
          width: values.width,
          height: values.height,
          weight: values.weight,
        },
        signatureRequired: false,
        creationMethod: LabelCreationMethod.MERCHANT_DASHBOARD,
      };

      try {
        validateDims([createLabelRequest]);

        let labelId: string;
        if (isMerchantGroup) {
          const merchantGroupcreateLabelRequest: ICreateLabelCallableDashboardRequest = {
            ...createLabelRequest,
            slug: merchant.slug,
          };
          labelId = await FirestoreService.merchantGroupCreateLabel(
            merchantGroupcreateLabelRequest
          );
        } else {
          labelId = await FirestoreService.createLabel(createLabelRequest);
        }

        const label = await FirestoreService.getLabelByMerchantIdAndLabelId(merchant.id, labelId);

        const {
          destination: { firstName, lastName },
          shipDate,
        } = label;
        const formattedDate = moment(shipDate).format(EXPORT_DATE_FORMAT);
        const fileName = `${COMPANY_NAME.toLowerCase()}_label_${formattedDate}_${firstName}_${lastName}.pdf`;

        await exportLabelsPDF([label], fileName);
        resetForm();
        message.success('Label created');
      } catch (e) {
        message.error(e?.message || GENERIC_ERROR_MESSAGE);
        console.error(e);
      }
    }

    setIsLoading(false);
  };

  const handleConfirm = () => {
    form.setFieldsValue({
      shipDate: shipDate,
    });

    createLabel(form.getFieldsValue());

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.setFieldsValue({
      shipDate: shipDate,
    });

    setIsModalVisible(false);
  };

  /**
   * Returns cutoff time message for merchant based on its associated pickup location settings.
   * For the holt toronto merchant slugs (evening and morning), returns hardcoded messages.
   * @returns string cutoff time message for the merchant
   */
  const getCutoffTimeMessage = (): string => {
    let message = `Any order submitted before ${
      pickupLocation.cutoffTime || '...'
    } will be delivered the same day between 4:30pm and 10:30pm. Otherwise, it will be delivered the following day based on your shipping schedule.`;

    const holtEveningMerchantSlugs = [
      'holt-renfrew-bloor',
      'holt-renfrew-bloor-2',
      'holt-renfrew-yorkdale',
      'holt-renfrew-square-one',
    ];

    const holtMorningMerchantSlugs = [
      'holt-renfrew-bloor-morning-delivery',
      'holt-renfrew-bloor-2-morning-delivery',
      'holt-renfrew-yorkdale-morning-delivery',
      'holt-renfrew-square-one-morning-delivery',
    ];

    if (holtEveningMerchantSlugs.includes(merchant.slug)) {
      message = `Labels created with this account are for SAME DAY delivery. Any order submitted before ${pickupLocation.cutoffTime} will be delivered the SAME DAY between 4:00 pm and 10:00pm.`;
    }

    if (holtMorningMerchantSlugs.includes(merchant.slug)) {
      message =
        'Labels created with this account are for NEXT DAY delivery. Delivery will take place between 9:00 am - 5:00 pm the NEXT DAY.';
    }

    return message;
  };

  return (
    <>
      <Alert message={getCutoffTimeMessage()} type="info" showIcon style={{ marginBottom: 20 }} />
      <Form form={form} layout="vertical" onFinish={createLabel}>
        {isMerchantGroup ? (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="Ship Date" name="shipDate">
                <Input disabled placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Merchant Name" name="merchantName">
                <Input disabled placeholder="Merchant Name" />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row>
            <Form.Item label="Ship Date" name="shipDate">
              <Input disabled placeholder="First Name" />
            </Form.Item>
          </Row>
        )}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please provide a First Name' }]}
            >
              <Input tabIndex={1} placeholder="First Name" />
            </Form.Item>
            <Form.Item
              label="Address Line 1"
              name="addressLine1"
              rules={[{ required: true, message: 'Please provide a Address Line 1' }]}
            >
              <Input tabIndex={3} placeholder="Address Line 1" autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: 'Please provide a city' }]}
            >
              <Input tabIndex={5} placeholder="City" autoComplete="off" />
            </Form.Item>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  tooltip="Number the driver will contact if there are any issues."
                  rules={[{ required: true, message: 'Please provide a Phone Number' }]}
                >
                  <Input tabIndex={8} type="tel" placeholder="Phone Number" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Email" name="email">
                  <Input tabIndex={9} type="email" placeholder="Email Address" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please provide a Last Name' }]}
            >
              <Input tabIndex={2} placeholder="Last Name" />
            </Form.Item>
            <Form.Item label="Address Line 2" name="addressLine2">
              <Input tabIndex={4} placeholder="Address Line 2" autoComplete="off" />
            </Form.Item>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Postal/Zip"
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: `Please provide a valid Postal or Zip code`,
                    },
                  ]}
                >
                  <Input tabIndex={6} placeholder="Postal Code or Zip Code" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Province/State" name="province">
                  <Input disabled tabIndex={7} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Country" name="country">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Order Number"
                  name="orderNumber"
                  tooltip="Order number from your system"
                >
                  <Input tabIndex={10} type="text" placeholder="Order Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                  label="Shipment Id"
                  name="shipmentId"
                  tooltip="Shipment id from your system"
                >
                  <Input tabIndex={10} type="text" placeholder="Shipment Id" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Notes"
              name="notes"
              tooltip="Order notes such as delivery instructions, buzzer codes, etc..."
            >
              <Input tabIndex={11} type="text" placeholder="Notes" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Weight (lbs)"
              name="weight"
              rules={[{ required: true, message: 'Please provide a Weight' }]}
            >
              <Input tabIndex={12} type="number" placeholder="Weight (lbs)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label="Length (in)"
                  name="length"
                  rules={[{ required: true, message: 'Please provide a Length' }]}
                >
                  <Input tabIndex={13} type="number" placeholder="Length (in)" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Width (in)"
                  name="width"
                  rules={[{ required: true, message: 'Please provide a Width' }]}
                >
                  <Input tabIndex={14} type="number" placeholder="Width (in)" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Height (in)"
                  name="height"
                  rules={[{ required: true, message: 'Please provide a Height' }]}
                >
                  <Input tabIndex={15} type="number" placeholder="Height (in)" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Create & Print Label
            </Button>
            <Button type="default" htmlType="submit" onClick={resetForm}>
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <Modal visible={isModalVisible} closable={false} width={'450px'} footer={null}>
        <Row justify="center" align="middle" style={{ margin: '.6em 0 .6em 0' }}>
          <Text style={{ fontSize: '25px' }}>
            <b>Confirmation</b>
          </Text>
        </Row>
        <Row justify="center" align="middle" style={{ margin: '10px 70px 10px 70px' }}>
          <Text style={{ textAlign: 'center', fontSize: '16px' }}>
            The cut off time for same day delivery has been reached. The new ship date will be
            <b> {`${shipDate}`}</b>. Would you like to proceed?
          </Text>
        </Row>
        <Row justify="center" align="middle" style={{ marginTop: '1.6em' }}>
          <Col style={{ padding: '.6em' }}>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ backgroundColor: 'lightgrey', color: 'white', width: '10em' }}
            >
              Cancel
            </Button>
          </Col>
          <Col style={{ padding: '.6em' }}>
            <Button type="primary" onClick={handleConfirm} style={{ width: '10em' }}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
