import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_ADD_ID,
});

if (process.env.REACT_APP_SWYFT_EMULATORS_FUNCTION_PORT) {
  app
    .functions()
    .useEmulator('localhost', parseInt(process.env.REACT_APP_SWYFT_EMULATORS_FUNCTION_PORT));
}

export default app;
